import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Linkify from "linkify-react";
import React from "react";
import MessageMention from "./MessageMention";

const s3URL = process.env.REACT_APP_S3_BASE_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    borderRadius: 10,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function ChatMediaItem({
  item,
  selectedContactDetails,
  openMedia,
}) {
  const classes = useStyles();

  const { message } = item;

  function renderMessage() {
    if (!message.url) {
      return "";
    }
    const finalUrl = /^https?:\/\//.test(message.url)
      ? message.url
      : `${s3URL}${message.url}`;
    switch (message.type) {
      case "video":
        return <CardMedia component="video" src={finalUrl} controls />;
      case "audio":
      case "voice":
      case "ptt":
        return (
          <audio controls>
            <source src={finalUrl.replace(".oga", ".mp3")} />{" "}
          </audio>
        );
      case "story_mention":
        return (
          <CardMedia
            alt="Story Content is no longer available"
            image={finalUrl}
            component="img"
          />
        );
      default:
        return (
          <CardMedia
            style={{
              maxHeight: 400,
            }}
            component="img"
            image={finalUrl}
          />
        );
    }
  }
  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => openMedia(item)}>
        {renderMessage(message)}
      </CardActionArea>
      {message.caption && (
        <CardContent>
          {(selectedContactDetails?.contact_type === "user" ||
            (selectedContactDetails?.contact_type === "group" &&
              !selectedContactDetails?.participants)) && (
            <Linkify
              options={{
                attributes: {
                  style: {
                    color: "blue",
                    wordBreak: "break-all",
                    border: "0px",
                    textDecoration: "underline",
                    backgroundColor: "transparent",
                  },
                },
                target: {
                  url: "_blank",
                },
              }}
              className="break-all"
            >
              {message.caption}
            </Linkify>
          )}
          {selectedContactDetails?.contact_type === "group" &&
            selectedContactDetails?.participants && (
              <MessageMention
                direction={item.direction}
                message={item.message.caption}
                participants={selectedContactDetails?.participants}
                media
              />
            )}
        </CardContent>
      )}
    </Card>
  );
}
