import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Typography from '@material-ui/core/Typography';
import { MovieOutlined, AttachmentOutlined, DescriptionOutlined, KeyboardVoiceOutlined } from '@material-ui/icons';
import clsx from 'clsx';

const StyledBadge = withStyles(theme => ({}))(Badge);
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(1)
		}
	},
	small: {
		width: theme.spacing(5),
		height: theme.spacing(5)
	},
	large: {
		width: theme.spacing(7),
		height: theme.spacing(7)
	}
}));

const ThumbImage = props => {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, 'w-auto inline-block')}>
			<StyledBadge
				overlap="circle"
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				badgeContent={
					props.showClose && (
						<button className="AddIcon p-0" tabIndex="0" type="button" onClick={props.onClick}>
							<CancelOutlinedIcon style={{ color: '#3B4452', zIndex: '0' }} />
						</button>
					)
				}
			>
				<Paper className={classes.root}>
					{props.fileUrl && <Avatar variant="rounded" className={classes.rounded} src={props.fileUrl} />}
					{props.type === 'video' && <MovieOutlined variant="rounded" className={classes.small} />}
					{props.type === 'audio' && <KeyboardVoiceOutlined variant="rounded" className={classes.small} />}
					{props.type === 'document' && <DescriptionOutlined variant="rounded" className={classes.small} />}
					{props.type === 'others' && <AttachmentOutlined variant="rounded" className={classes.small} />}
				</Paper>
				{props.text && <Typography className="break-all">{props.text}</Typography>}
			</StyledBadge>
		</div>
	);
};
export default ThumbImage;
