import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  leads: [],
  contacts: [],
  selectedContact: {},
  templateMessage: {},
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    leadsAction: (state, action) => {
      const { payload } = action;
      state.leads = payload?.leads;
    },
    contactsAction: (state, action) => {
      const { payload } = action;
      state.contacts = payload.contacts;
    },
    selectedContactAction: (state, action) => {
      const { payload } = action;
      state.selectedContact = payload.selectedContact;
    },
    updateTemplate: (state, action) => {
      return {
        ...state,
        campaign: {
          ...state?.campaign,
          template: action.payload,
        },
      };
    },
    updateTemplateMessage: (state, action) => {
      return {
        ...state,
        templateMessage: action.payload,
      };
    },
  },
});

export const {
  leadsAction,
  contactsAction,
  selectedContactAction,
  updateTemplate,
  updateTemplateMessage,
} = chatSlice.actions;

export const chats = (state) => state.chats;

export default chatSlice.reducer;
