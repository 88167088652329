import React from "react";
import io from "socket.io-client";

let socket;

const getSocketInstance = () => {
  let accessToken;
  accessToken = localStorage.getItem("jwt_token");
  accessToken = accessToken ? accessToken : "";

  if (!socket) {
    socket = io.connect(process.env.REACT_APP_BASE_API_URL, {
      query: `jwt=${accessToken}`,
      transports: ["websocket"],
    });
    socket.on("connect", () =>
      console.log(socket.id, socket.connected, "connected")
    );
    socket.on("disconnect", () => console.log(socket, "disconnected"));
  }

  return socket;
};

export let ws = {
  getSocketInstance,
};

export const SocketContext = React.createContext();
