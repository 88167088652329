import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routesConfig } from "./routes/routesConfig";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { SocketContext, ws } from "./socketContext";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const router = createBrowserRouter(routesConfig);

const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      outlinedPrimary: {
        borderRadius: "50px",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "21px",
      },
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <SocketContext.Provider value={ws}>
      <ToastContainer
        position="bottom-left"
        hideProgressBar
        autoClose={2000}
        closeOnClick
      />
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </SocketContext.Provider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
