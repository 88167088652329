import { DialogActions, ListItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import { useEffect, useMemo, useState } from "react";

import CircularIndeterminate from "./circular-progress";
import { getFiles } from "../services/ChatService";
import { useSelector } from "react-redux";
import { settings } from "../Setting/settingSlice";

const FileListPopup = (props) => {
  const { closeDialog, setMediaFile, onlyImage, onlyVideo } = props;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [singleFile] = useState(props.isSingleFile);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [singleMediaFile, setSingleMediaFile] = useState([]);
  const [selectAllFiles, setSelectAllFiles] = useState(false);
  const [filesData, setFilesData] = useState(false);
  const [isFilesFetched, setIsFileFetched] = useState(false);
  const settingData = useSelector(settings);
  const {
    auth: { authToken },
  } = settingData;

  useEffect(() => {
    async function callFiles() {
      const filesData = await getFiles(authToken);
      setFilesData(filesData);
      setIsFileFetched(true);
    }
    callFiles();
  }, [authToken]);

  useEffect(() => {
    if (isFilesFetched && filesData?.data?.length !== 0) {
      if (onlyImage) {
        const fileListData = filesData?.data?.filter((list) =>
          list.file_mime_type.includes("image")
        );
        setFilesList(fileListData);
      } else if (onlyVideo) {
        const fileListData = filesData?.data?.filter((list) =>
          list.file_mime_type.includes("video")
        );
        setFilesList(fileListData);
      } else {
        setFilesList(filesData?.data);
      }
    }
  }, [isFilesFetched, filesData, onlyImage, onlyVideo]);

  const handleChangeInFile = (e, file) => {
    if (!singleFile) {
      const fileArr = selectedFiles;
      const mediaArr = mediaFiles;

      const fileIndex = fileArr.indexOf(e.target.value);

      const mediaIndex = mediaArr
        .map((data) => {
          return data._id;
        })
        .indexOf(e.target.value);

      if (fileIndex > -1) {
        fileArr.splice(fileIndex, 1);
      } else {
        fileArr.push(e.target.value);
      }

      if (mediaIndex > -1) {
        mediaArr.splice(mediaIndex, 1);
      } else {
        mediaArr.push(file);
        // mediaArr.push({
        // 	file_extension: file.file_extension,
        // 	file_mime_type: file.file_mime_type,
        // 	file_name: file.file_name,
        // 	file_path: file.file_path,
        // 	file_source_name: file.file_source_name
        // });
      }
      setMediaFiles([...mediaArr]);
      setSelectedFiles([...fileArr]);
    } else {
      setSingleMediaFile(file);
    }
  };

  const isFileChecked = (id) => {
    return Boolean(selectedFiles.includes(id));
  };

  const handleUpload = () => {
    if (!singleFile) {
      const dummyArr = mediaFiles.map((file) => {
        const fileObj = {
          file_extension: file.file_extension,
          file_mime_type: file.file_mime_type,
          file_name: file.file_name,
          file_s3_path: file.file_path,
          file_source_name: file.file_source_name,
        };
        return fileObj;
      });
      setMediaFile(dummyArr);
      closeDialog();
    } else {
      const fileObj = {
        file_extension: singleMediaFile.file_extension,
        file_mime_type: singleMediaFile.file_mime_type,
        file_name: singleMediaFile.file_name,
        file_s3_path: singleMediaFile.file_path,
        file_source_name: singleMediaFile.file_source_name,
      };
      setMediaFile(fileObj);
      closeDialog();
    }
    // closeDialog();
  };

  const handleSelectAllFiles = (event) => {
    const { checked } = event.target;
    setSelectAllFiles(checked);
  };

  useEffect(() => {
    const selectedFileIds = filesList.map((file) => file._id);
    if (selectAllFiles) {
      setMediaFiles([...filesList]);
      setSelectedFiles([...selectedFileIds]);
    } else {
      setMediaFiles([]);
      setSelectedFiles([]);
    }
  }, [selectAllFiles, filesList]);

  const handleListItemClick = (e, file) => {
    if (!singleFile) {
      handleChangeInFile(e, file);
    } else setSingleMediaFile(file);
  };
  return (
    <div className="flex flex-col relative">
      {!singleFile && (
        <div
          className="flex items-center mt-4 cursor-pointer"
          onClick={() => setSelectAllFiles((prev) => !prev)}
          onKeyDown={() => setSelectAllFiles((prev) => !prev)}
          role="checkbox"
          aria-checked
          tabIndex={0}
        >
          <Checkbox
            checked={selectAllFiles}
            onChange={(e) => handleSelectAllFiles(e)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <Typography>Select All Files</Typography>
        </div>
      )}

      <div
        id="chatContainer"
        className="flex flex-1 flex-col overflow-y-auto transition-all"
      >
        {useMemo(() => {
          return (
            <div className="flex flex-col pt-4 px-8 ltr:pl-8 rtl:pr-56 pb-40">
              {isFilesFetched ? (
                <>
                  {filesList && (
                    <>
                      {filesList?.length > 0 ? (
                        filesList?.map((file, index) => (
                          <div className="flex items-center">
                            {!singleFile && (
                              <Checkbox
                                checked={isFileChecked(file._id)}
                                onChange={(e) => handleChangeInFile(e, file)}
                                value={file._id}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                disabled={singleFile}
                              />
                            )}
                            <ListItem
                              button
                              key={index}
                              onClick={() =>
                                handleListItemClick(
                                  { target: { value: file._id } },
                                  file
                                )
                              }
                              className="p-0 z-[1]"
                            >
                              {singleFile && (
                                <Radio
                                  value={file._id}
                                  name="selected-file"
                                  checked={singleMediaFile._id === file._id}
                                  onChange={(e) => handleChangeInFile(e, file)}
                                />
                              )}
                              <ListItemText
                                id={index + 1}
                                classes={{
                                  root: "min-w-px px-16",
                                  primary: "font-medium text-14 truncate",
                                  secondary: "truncate",
                                }}
                                primary={file.file_source_name}
                              />
                            </ListItem>
                          </div>
                        ))
                      ) : (
                        <div className="flex flex-1 items-center justify-center h-full">
                          <Typography color="textSecondary" variant="h6">
                            There are no Files!
                          </Typography>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className="flex flex-1 items-center justify-center h-full">
                  <CircularIndeterminate />
                </div>
              )}
            </div>
          );
        }, [filesList, selectedFiles, singleMediaFile])}
        <DialogActions className="justify-end p-4 pb-16">
          <div className="px-6">
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={!selectedFiles.length}
            >
              Upload
            </Button>
          </div>
        </DialogActions>
      </div>
    </div>
  );
};

export default FileListPopup;
