import axios from "axios";
import { toast } from "react-toastify";

export const loginUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/api/zoho/login`,
        data
      );
      if (response?.data.success) {
        return resolve(response?.data);
      }
    } catch (error) {
      toast.error("Cannot Logged In", {
        theme: "colored",
      });
      return reject();
    }
  });
};

export const verifyChannelDetails = async (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        // url: `${process.env.REACT_APP_BASE_API_URL}/api/zoho/whatsapp-accounts/login`,
        url: `${process.env.REACT_APP_BASE_API_URL}/api/whatsapp-accounts/login`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      const response = await axios(config);
      if (response?.data?.success) {
        return resolve(response?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.errors?.message || error.message, {
        theme: "colored",
      });
      return reject();
    }
  });
};

export const fetchPhoneDetails = async (channelToken, managerId, authToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        // url: `${process.env.REACT_APP_BASE_API_URL}/api/zoho/whatsapp-accounts/${managerId}/phone-numbers`,
        url: `${process.env.REACT_APP_BASE_API_URL}/api/whatsapp-accounts/${managerId}/phone-numbers`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: { channelToken },
      };
      const response = await axios(config);
      if (response?.data.success) {
        return resolve(response?.data);
      }
    } catch (error) {
      console.log("Error in fetchPhoneDetails :", error);
      toast.error("Error While Fetching Phone Numbers.", {
        theme: "colored",
      });
      return reject();
    }
  });
};

export const addChannel = async (data, authToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        // url: `${process.env.REACT_APP_BASE_API_URL}/api/zoho/whatsapp-accounts/add`,
        url: `${process.env.REACT_APP_BASE_API_URL}/api/whatsapp-accounts/add`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data,
      };
      const response = await axios(config);
      if (response?.data.success) {
        toast.success("Channel Created Successfully.", {
          theme: "colored",
        });
        return resolve(response?.data);
      }
    } catch (error) {
      console.log("Error in addChannel :", error);
      toast.error(error?.response?.data?.errors?.message || "Error While Adding Channel.", {
        theme: "colored",
      });
      return reject();
    }
  });
};

export const syncOfficialTemplate = async (channelId, authToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        // url: `${process.env.REACT_APP_BASE_API_URL}/api/zoho/message-templates/official-templates`,
        url: `${process.env.REACT_APP_BASE_API_URL}/api/message-templates/official-templates`,
        method: "GET",
        params: { channel_id: channelId },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios(config);
      if (response?.data.success) {
        return resolve(response?.data);
      }
    } catch (error) {
      console.log("Error in syncOfficialTemplate :", error);
      return reject();
    }
  });
};

export const getAllChannels = async (authToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        url: `${process.env.REACT_APP_BASE_API_URL}/api/integrations`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios(config);
      if (response?.data.success) {
        return resolve(response?.data);
      }
    } catch (error) {
      console.log("Error in getAllChannels :", error);
      toast.error(error.message, {
        theme: "colored",
      });
      return reject();
    }
  });
};
