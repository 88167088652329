import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { DescriptionOutlined } from "@material-ui/icons";

const s3URL = process.env.REACT_APP_BASE_S3_URL;

const useStyles = makeStyles({
  root: {
    minWidth: "200px",
    maxWidth: "500px",
    borderRadius: "10px",
  },
});

export default function PdfDocument({ item }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <DescriptionOutlined
            style={{ width: "40px", height: "40px", margin: "5px" }}
          />
          {item.message?.filename && (
            <Typography variant="body2" color="textSecondary" component="p">
              {item.message?.filename}
            </Typography>
          )}
          <a
            href={
              /^https?:\/\//.test(item.message.url)
                ? item.message.url
                : `${s3URL}${item.message.url}`
            }
          >
            <GetAppRoundedIcon />
          </a>
        </CardContent>
      </CardActionArea>
      {/* <CardActionArea>
				<Tooltip title="Download" aria-label="add" style={{ float: 'right', margin: '10px' }}>
					<Fab color="secondary" className={classes.absolute}>
						<GetAppRoundedIcon />
					</Fab>
				</Tooltip>
			</CardActionArea> */}
    </Card>
  );
}
