import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {
  verifyChannelDetails,
  fetchPhoneDetails,
  addChannel,
  syncOfficialTemplate,
  getAllChannels,
} from "../services/AuthService";
import {
  IconButton,
  Tooltip,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  settings,
  channelsAction,
  officalTemplatesAction,
  channelFormAction,
} from "./settingSlice";

const CreateChannelForm = () => {
  const [verifyToken, setVerifyToken] = useState("");
  const settingData = useSelector(settings);
  const dispatch = useDispatch();
  const {
    auth: { user, authToken },
    organisation,
    channelForm,
  } = settingData;

  const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
  const webhookUrl = `${baseApiUrl}/api/webhooks/facebook-whatsapp-incoming-webhook`;

  useEffect(() => {
    setVerifyToken(user?.account_id);
  }, [user?.account_id]);

  useEffect(() => {
    dispatch(
      channelFormAction({
        type: "name",
        data: { name: organisation?.company_name },
      })
    );
  }, [organisation, dispatch]);

  const handleSaveChannel = async () => {
    try {
      const response = await verifyChannelDetails(channelForm, authToken);
      if (response?.data?.status) {
        const fetchedPhoneDetails = await fetchPhoneDetails(
          channelForm?.token,
          channelForm?.managerId,
          authToken
        );
        const matchedPhoneData = fetchedPhoneDetails?.data?.filter(
          (eachPhone) => eachPhone?.setup?.phone_id === channelForm?.phoneId
        )[0];
        if (matchedPhoneData) {
          const addChannelData = {
            business: matchedPhoneData?.business,
            channel_access_token: channelForm?.token,
            phoneNumber: matchedPhoneData?.setup,
            providerId: matchedPhoneData?.providerId,
          };
          const res = await addChannel(addChannelData, authToken);
          if (res?.success) {
            const channelsRes = await getAllChannels(authToken);
            dispatch(channelsAction({ channels: channelsRes?.data }));
            const templateRes = await syncOfficialTemplate(
              res?.data?._id,
              authToken
            );
            dispatch(officalTemplatesAction({ templates: templateRes }));
          }
        }
      }
    } catch (error) {
      console.log("error in handleSaveChannel", error);
    }
  };

  return (
    <div className="flex flex-col flex-wrap w-8/12 m-2">
      <h3 className="mb-4">Configure Channel</h3>
      <TextField
        value={channelForm.managerId}
        required
        name="business_id"
        id="business_id"
        type="text"
        variant="outlined"
        label="Business Manager Id"
        margin="normal"
        onChange={
          (e) =>
            dispatch(
              channelFormAction({
                type: "manager_id",
                data: { managerId: e.target.value },
              })
            )
          // setChannelForm((prevForm) => ({
          //   ...prevForm,
          //   managerId: e.target.value,
          // }))
        }
        // helperText={!channelForm.exitingProvider && businessIdError ? t('Common.ManagerIdError') : ''}
        // error={!channelForm.exitingProvider && businessIdError}
        // disabled={channelForm.exitingProvider}
        // onBlur={() => (channelForm.businessId === '' ? setBusinessIdError(true) : setBusinessIdError(false))}
      />
      <TextField
        value={channelForm.phoneId}
        required
        name="phone_id"
        id="phone_id"
        type="text"
        variant="outlined"
        label="Phone Id"
        margin="normal"
        onChange={
          (e) =>
            dispatch(
              channelFormAction({
                type: "phone_id",
                data: { phoneId: e.target.value },
              })
            )
          // setChannelForm((prevForm) => ({
          //   ...prevForm,
          //   phoneId: e.target.value,
          // }))
        }
        // helperText={!channelForm.exitingProvider && businessIdError ? t('Common.ManagerIdError') : ''}
        // error={!channelForm.exitingProvider && businessIdError}
        // disabled={channelForm.exitingProvider}
        // onBlur={() => (channelForm.businessId === '' ? setBusinessIdError(true) : setBusinessIdError(false))}
      />
      <TextField
        value={webhookUrl}
        id="webhook_url"
        label="Webhook Url"
        disabled
        variant="outlined"
        margin="normal"
        multiline
        className="w-full mt-10"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className="mx-8">
              <CopyToClipboard text={webhookUrl}>
                <Tooltip title="Copy This Url">
                  <IconButton
                    aria-label="Reset Channel"
                    style={{ color: "blue" }}
                  >
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        value={verifyToken}
        id="verify_token"
        label="Verify Token"
        disabled
        variant="outlined"
        margin="normal"
        className="w-full mt-10"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className="mx-8">
              <CopyToClipboard text={verifyToken}>
                <Tooltip title="Copy This Token">
                  <IconButton
                    aria-label="resetChannel"
                    style={{ color: "blue" }}
                  >
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        value={channelForm.token}
        required
        multiline
        rows={2}
        name="access_token"
        id="access_token"
        type="text"
        variant="outlined"
        label="Access Token"
        margin="normal"
        onChange={(e) => {
          dispatch(
            channelFormAction({
              type: "access_token",
              data: { token: e.target.value },
            })
          );
          // setChannelForm((prevForm) => ({
          //   ...prevForm,
          //   token: e.target.value,
          // }));
        }}
        // helperText={!channelForm.exitingProvider && productTokenError ? t('Common.AccessTokenError') : ''}
        // error={!channelForm.exitingProvider && productTokenError}
        // disabled={channelForm.exitingProvider}
        // onBlur={() =>
        // 	channelForm.channelToken === '' ? setProductTokenError(true) : setProductTokenError(false)
        // }
      />
      <div className="self-end sticky right-8">
        <Button variant="contained" color="primary" onClick={handleSaveChannel}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default CreateChannelForm;
