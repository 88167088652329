import {
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Autocomplete } from "@material-ui/lab";
import AddAttachmentPopup from "./AddAttachmentPopup";
import { updateTemplate, updateTemplateMessage } from "../ChatApp/chatSlice";
import TemplateMessage from "./TemplateMessage";
import { chatUploadFiles } from "../services/ChatService";
import {
  snakeCaseToTitleCase,
  isJsonString,
  zohoPlaceholders,
} from "../common";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { settings } from "../Setting/settingSlice";
import { chats } from "../ChatApp/chatSlice";

const s3URL = process.env.REACT_APP_BASE_S3_URL;

const EmmaTemplatePopup = (props) => {
  const dispatch = useDispatch();
  const [templateData, setTemplateData] = useState({});
  const [template, setTemplate] = useState("");
  // const [isTemplateSet, setIsTemplate] = useState(false);
  const [headerTemplateData, setHeaderTemplateData] = useState({});
  const [headerConsist, setHeaderConsist] = useState(false);
  const [headerMediaType, setHeaderMediaType] = useState("");
  const [selectedTemplated, setSelectedTemplated] = useState({});
  const [templateJson, setTemplateJson] = useState(null);
  const [multiMedia, setMultiMedia] = useState(null);
  const [headerError, setHeaderError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [placeholderError, setPlaceholderError] = useState({});
  const [headerPlaceholderError, setHeaderPlaceholderError] = useState({});
  const [bodyPlaceHolder, setBodyPlaceHolder] = useState();
  const [headerPlaceHolder, setHeaderPlaceHolder] = useState();
  const [templatesArr, setTemplatesArr] = useState([]);
  const [finalTemplateJson, setFinalTemplateJson] = useState();
  const [quickReplyButtonCallbackArr, setQuickReplyButtonCallbackArr] =
    useState([]);
  const [buttonType, setButtonType] = useState("");
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [templatesDataArr, setTemplatesDataArr] = useState([]);

  const placeHolderFixedFields = [];
  zohoPlaceholders?.["default"].forEach((placeholder) => {
    placeHolderFixedFields.push({
      originalLabel: placeholder,
      value: `{${placeholder}}`,
      label: snakeCaseToTitleCase(placeholder),
    });
  });

  const settingData = useSelector(settings);
  const chatsData = useSelector(chats);

  const {
    auth: { authToken },
  } = settingData;

  const { leads } = chatsData;
  const selectedLead = leads[0];

  useEffect(() => {
    const { officalTemplates } = settingData;
    setTemplatesDataArr(officalTemplates);
  }, [settingData]);

  useEffect(() => {
    if (templatesDataArr?.data?.length) {
      // setIsTemplate(true);
      const templatesTempArr = [];
      templatesDataArr.data.forEach((templateValue) => {
        if (templateValue.status.toUpperCase() === "APPROVED") {
          templatesTempArr.push({
            value: templateValue.id
              ? templateValue.id
              : templateValue.name.concat(` (${templateValue.language})`),
            label: templateValue.name.concat(` (${templateValue.language})`),
          });
          if (
            props.whatsappTemplateJson &&
            props.whatsappTemplateJson.name === templateValue.name &&
            props.whatsappTemplateJson.language.code === templateValue.language
          ) {
            setSelectedTemplated({
              value: props.whatsappTemplateJson.id
                ? props.whatsappTemplateJson.id
                : props.whatsappTemplateJson.name.concat(
                    ` (${props.whatsappTemplateJson.language.code})`
                  ),
              label: props.whatsappTemplateJson.name.concat(
                ` (${props.whatsappTemplateJson.language.code})`
              ),
            });
            if (props.whatsappTemplateJson.components) {
              const bodyComponent = props.whatsappTemplateJson.components.find(
                (e) => e.type === "body"
              );
              if (bodyComponent) {
                const bodyTemplateData = {};
                for (
                  let index = 0;
                  index < bodyComponent.parameters.length;
                  index += 1
                ) {
                  bodyTemplateData[`{{${index + 1}}}`] =
                    bodyComponent.parameters[index].text;
                }

                setTemplateData(bodyTemplateData);
              }
              const headerComponent =
                props.whatsappTemplateJson.components.find(
                  (e) => e.type === "header"
                );
              if (headerComponent) {
                const headerTemplateDataConstruct = {};
                for (
                  let index = 0;
                  index < headerComponent.parameters.length;
                  index += 1
                ) {
                  if (headerComponent.parameters[index].type === "text") {
                    headerTemplateDataConstruct[`{{${index + 1}}}`] =
                      headerComponent.parameters[index].text;
                  } else {
                    setMultiMedia({
                      file_s3_path: headerComponent.parameters[index][
                        headerComponent.parameters[index].type
                      ].link.replace(s3URL, ""),
                      file_source_name:
                        headerComponent.parameters[index][
                          headerComponent.parameters[index].type
                        ].filename,
                    });
                  }
                }
                setHeaderTemplateData(headerTemplateData);
              }
              const dummyQuickReplyButtonCallbackArr = [];
              let buttonTypeVal = "";
              props.whatsappTemplateJson.components.forEach((component) => {
                if (
                  component.type === "button" &&
                  (component.sub_type === "quick_reply" ||
                    component.sub_type === "url")
                ) {
                  buttonTypeVal = component.sub_type;
                  dummyQuickReplyButtonCallbackArr.push(
                    component.parameters[0].payload
                  );
                }
              });
              setButtonType(buttonTypeVal);
              setQuickReplyButtonCallbackArr(dummyQuickReplyButtonCallbackArr);
            }
            setTemplate(
              templateValue.id
                ? templateValue.id
                : templateValue.name.concat(` (${templateValue.language})`)
            );
            setTemplateError(false);
            // setIsTemplate(false);
          }
        }
      });
      setTemplatesArr(templatesTempArr);
    }
  }, [templatesDataArr]);

  function handleMediaFile(e) {
    chatUploadFiles(e.target.files[0], null, authToken).then((response) => {
      const uploadedMediaFiles = response.data;
      setMultiMedia(uploadedMediaFiles);
      setHeaderError(false);
    });
  }

  useEffect(() => {
    if (template) {
      setMultiMedia(null);
      setTemplateData({});
      setHeaderTemplateData({});
      let dummyPlaceholderArr;
      const dummyPlaceholderObj = {};
      const dummyPlaceholderErrObj = {};
      const dummyHeaderPlaceholderObj = {};
      const dummyHeaderPlaceholderErrObj = {};
      let selectedTemplatedJson = {};
      templatesDataArr.data.forEach((individualTemplate) => {
        if (
          individualTemplate.id === template ||
          individualTemplate.name.concat(
            ` (${individualTemplate.language})`
          ) === template
        ) {
          selectedTemplatedJson = individualTemplate;
        }
      });
      // dummyTemplateArray.forEach(individualTemplate => {
      // 	if (individualTemplate.id === template) {
      // 		selectedTemplatedJson = individualTemplate;
      // 	}
      // });
      if (Object.keys(selectedTemplatedJson).length > 0) {
        selectedTemplatedJson.components.forEach((componentValue) => {
          if (componentValue.type === "BODY") {
            dummyPlaceholderArr = getPlaceHolderData(componentValue.text);
            setBodyPlaceHolder(dummyPlaceholderArr);
          }
          if (dummyPlaceholderArr) {
            dummyPlaceholderArr.forEach((placeholderValue) => {
              dummyPlaceholderObj[placeholderValue] =
                templateData[placeholderValue] || "";
              dummyPlaceholderErrObj[placeholderValue] = false;
            });
          }
          if (componentValue.type === "HEADER") {
            setHeaderConsist(true);
            switch (componentValue.format) {
              case "DOCUMENT":
                setHeaderMediaType("document");
                break;
              case "VIDEO":
                setHeaderMediaType("video");
                break;
              case "IMAGE":
                setHeaderMediaType("image");
                break;
              case "TEXT":
                setHeaderMediaType("text");
                dummyPlaceholderArr = getPlaceHolderData(componentValue.text);
                setHeaderPlaceHolder(dummyPlaceholderArr);
                if (dummyPlaceholderArr) {
                  dummyPlaceholderArr.forEach((placeholderValue) => {
                    dummyHeaderPlaceholderObj[placeholderValue] =
                      headerTemplateData[placeholderValue] || "";
                    dummyHeaderPlaceholderErrObj[placeholderValue] = false;
                  });
                }
                break;
              default:
                break;
            }
          }
          if (componentValue.type === "BUTTONS") {
            const havingQuickReply = componentValue.buttons.some((button) => {
              return button.type === "QUICK_REPLY";
            });
            if (havingQuickReply) {
              setButtonType("quick_reply");
              const dummyArr = [];
              if (!props.whatsappTemplateJson) {
                componentValue.buttons.forEach((button) => {
                  if (button.type === "QUICK_REPLY") {
                    dummyArr.push("");
                  }
                });
                setQuickReplyButtonCallbackArr(dummyArr);
              }
            } else {
              const havingDynamicReply = componentValue.buttons.some(
                (button) => {
                  return button.type === "URL";
                }
              );
              if (havingDynamicReply) {
                const dummyArr = [];
                if (!props.whatsappTemplateJson) {
                  componentValue.buttons.forEach((button) => {
                    if (button.type === "URL") {
                      const dynamicDataArr = getPlaceHolderData(button.url);
                      if (dynamicDataArr && dynamicDataArr.length > 0) {
                        dummyArr.push("");
                      }
                    }
                  });
                  setQuickReplyButtonCallbackArr(dummyArr);
                }
                setButtonType("url");
              }
            }
          }
        });
        setTemplateData(dummyPlaceholderObj);
        setHeaderTemplateData(dummyHeaderPlaceholderObj);
        setTemplateJson(selectedTemplatedJson);
        setPlaceholderError(dummyPlaceholderErrObj);
        setHeaderPlaceholderError(dummyHeaderPlaceholderErrObj);
      }
    }
  }, [template]);

  useEffect(() => {
    if (templateJson) {
      constructFinalJson();
    }
  }, [
    templateJson,
    templateData,
    headerTemplateData,
    quickReplyButtonCallbackArr,
    multiMedia,
  ]);

  const validatePlaceholders = () => {
    const dummyPlaceholderErrObj = {};
    let foundError = false;
    Object.keys(templateData).forEach((placeholderKey, index) => {
      if (templateData[placeholderKey] === "") {
        dummyPlaceholderErrObj[placeholderKey] = true;
        foundError = true;
      } else {
        dummyPlaceholderErrObj[placeholderKey] = false;
      }
    });
    setPlaceholderError(dummyPlaceholderErrObj);
    return foundError;
  };

  const validateHeaderPlaceholders = () => {
    const dummyPlaceholderErrObj = {};
    let foundError = false;
    Object.keys(headerTemplateData).forEach((placeholderKey, index) => {
      if (headerTemplateData[placeholderKey] === "") {
        dummyPlaceholderErrObj[placeholderKey] = true;
        foundError = true;
      } else {
        dummyPlaceholderErrObj[placeholderKey] = false;
      }
    });
    setHeaderPlaceholderError(dummyPlaceholderErrObj);
    return foundError;
  };

  const constructFinalJson = () => {
    const finalJson = {
      namespace: props.channelKey,
      name: templateJson.name,
      language: {
        policy: "deterministic",
        code: templateJson.language,
      },
      components: [],
    };

    if (headerConsist && headerMediaType !== "text") {
      const dummyHeaderObj = {
        type: "header",
      };
      const headerParameters = [];
      const finalUrl = multiMedia?.file_s3_path
        ? s3URL.concat(multiMedia?.file_s3_path)
        : multiMedia;

      switch (headerMediaType) {
        case "document":
          if (multiMedia) {
            headerParameters.push({
              type: "document",
              document: {
                filename:
                  multiMedia.file_source_name ||
                  (Math.random() + 1).toString(36).substring(7),
                link: finalUrl,
              },
            });
          }
          break;
        case "image":
          if (multiMedia) {
            headerParameters.push({
              type: "image",
              image: {
                link: finalUrl,
              },
            });
          }
          break;
        case "video":
          if (multiMedia) {
            headerParameters.push({
              type: "video",
              video: {
                link: finalUrl,
              },
            });
          }
          break;
        default:
          break;
      }
      if (headerParameters.length > 0) {
        dummyHeaderObj.parameters = headerParameters;
      }
      finalJson.components.push(dummyHeaderObj);
    } else if (
      headerConsist &&
      headerMediaType === "text" &&
      headerPlaceHolder
    ) {
      const dummyHeaderObj = {
        type: "header",
      };
      const headerParametersArr = [];
      Object.keys(headerTemplateData).forEach((placeholderKey) => {
        headerParametersArr.push({
          type: "text",
          text: headerTemplateData[placeholderKey],
        });
      });
      dummyHeaderObj.parameters = headerParametersArr;

      finalJson.components.push(dummyHeaderObj);
    }

    if (bodyPlaceHolder) {
      const dummyBodyObj = {
        type: "body",
      };
      const dummyPlaceholderArr = [];
      Object.keys(templateData).forEach((placeholderKey) => {
        dummyPlaceholderArr.push({
          type: "text",
          text: templateData[placeholderKey],
        });
      });

      dummyBodyObj.parameters = dummyPlaceholderArr;
      finalJson.components.push(dummyBodyObj);
    }

    if (quickReplyButtonCallbackArr.length > 0) {
      quickReplyButtonCallbackArr.forEach((buttonCallback, index) => {
        const payload = JSON.stringify({
          id: buttonCallback,
          orderId: "{order_id}",
          cus_id: "{customer_id}",
        });
        const dummyBodyObj = {
          type: "button",
          sub_type: buttonType,
          index: index.toString(),
          parameters: [
            {
              type: "payload",
              payload: buttonType === "url" ? buttonCallback : payload,
            },
          ],
        };
        finalJson.components.push(dummyBodyObj);
      });
    }
    if (finalJson.components.length <= 0) {
      delete finalJson.components;
    }
    setFinalTemplateJson(finalJson);
    dispatch(updateTemplate({}));
    dispatch(
      updateTemplateMessage({
        finalTemplateJson: finalJson,
        templatesDataArr,
      })
    );
  };

  const isValidTemplateName = () => {
    if (template === "") {
      setTemplateError(true);
    } else {
      setTemplateError(false);
    }
  };

  const isValidPlaceholder = (placeholderKey) => {
    const dummyObj = { ...placeholderError };
    if (templateData[placeholderKey] === "") {
      dummyObj[placeholderKey] = true;
    } else {
      dummyObj[placeholderKey] = false;
    }
    setPlaceholderError(dummyObj);
  };

  const isValidHeaderPlaceholder = (placeholderKey) => {
    const dummyObj = { ...headerPlaceholderError };
    if (headerTemplateData[placeholderKey] === "") {
      dummyObj[placeholderKey] = true;
    } else {
      dummyObj[placeholderKey] = false;
    }
    setHeaderPlaceholderError(dummyObj);
  };

  useEffect(() => {
    if (templateData) setBodyPlaceHolderLabel(templateData);
  }, [templateData]);

  const [bodyPlaceHolderLabel, setBodyPlaceHolderLabel] = useState({});
  const handleBodyPlaceholders = (event) => {
    // console.log('event.target.value', JSON.parse(event.target.value));
    const dummyObj = { ...templateData };
    let data;
    if (isJsonString(event.target.value)) {
      data = JSON.parse(event.target.value);
      setBodyPlaceHolderLabel((prev) => ({
        ...prev,
        [event.target.name]: data.placeholder,
      }));
    } else {
      data = event.target.value;
      setBodyPlaceHolderLabel((prev) => ({
        ...prev,
        [event.target.name]: data,
      }));
    }
    dummyObj[event.target.name] = data.placeholder ? data.placeholder : data;
    setTemplateData(dummyObj);
  };

  const [headerPlaceHolderLabel, setHeaderPlaceHolderLabel] = useState({});
  const handleHeaderPlaceholders = (event) => {
    const dummyObj = { ...headerTemplateData };
    let data;
    if (isJsonString(event.target.value)) {
      data = JSON.parse(event.target.value);
      setHeaderPlaceHolderLabel((prev) => ({
        ...prev,
        [event.target.name]: data.placeholder,
      }));
    } else {
      data = event.target.value;
      setHeaderPlaceHolderLabel((prev) => ({
        ...prev,
        [event.target.name]: data,
      }));
    }
    dummyObj[event.target.name] = data.placeholder ? data.placeholder : data;
    setHeaderTemplateData(dummyObj);
  };

  const handleQuickReplyButtonCallbackId = (event, index) => {
    const dummyArr = [...quickReplyButtonCallbackArr];
    dummyArr[index] = event.target.value;
    setQuickReplyButtonCallbackArr(dummyArr);
  };

  const getPlaceHolderData = (textString) => {
    const placeholders = /(\{\{[1-9][0-9]?\}\})/g;
    const placeholderArr = textString.match(placeholders);
    return placeholderArr;
  };

  const getMediaType = (mediaType) => {
    switch (mediaType) {
      case "DOCUMENT":
        return "Upload Document Required.";
      case "VIDEO":
        return "Upload Video Required.";
      case "IMAGE":
        return "Upload Image Required.";
      default:
        break;
    }
    return "";
  };

  const getFileType = (mediaType) => {
    switch (mediaType) {
      case "DOCUMENT":
        return ".heic, .hevc, .heif, .pdf, .doc, .docx, application/msword, application/pdf";
      case "VIDEO":
        return ".gif, .mp4, .m4p";
      case "IMAGE":
        return ".png, .jpg, .jpeg";
      default:
        break;
    }
    return "";
  };

  const getHeaderPlaceHolderFields = (textString) => {
    const placeholders = /(\{\{[1-9][0-9]?\}\})/g;
    const placeholderArr = textString.match(placeholders);
    return (
      <div>
        {headerTemplateData &&
          placeholderArr &&
          placeholderArr.map((placeholderVal, index) => {
            return (
              <div
                className="grid sm:grid-cols-1 md:grid-cols-3 sm:gap-2 md:gap-4 sm:pt-8"
                key={"placeholder_grid".concat(index)}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  key={"placeholder_grid_item_name".concat(index)}
                >
                  {"Placeholder ".concat(placeholderVal)}
                </Typography>
                <TextField
                  value={headerPlaceHolderLabel[placeholderVal]}
                  required
                  InputLabelProps={{
                    shrink: headerPlaceHolderLabel[placeholderVal],
                  }}
                  name={placeholderVal}
                  id={index.toString()}
                  type="text"
                  variant="outlined"
                  label="Placeholder Value"
                  margin="normal"
                  onChange={handleHeaderPlaceholders}
                  onBlur={() => isValidHeaderPlaceholder(placeholderVal)}
                  helperText={
                    headerPlaceholderError[placeholderVal]
                      ? "This field cannot be blank."
                      : ""
                  }
                  error={headerPlaceholderError[placeholderVal]}
                />
                <TextField
                  placeholder="Insert Placeholder"
                  value={headerPlaceHolderLabel[placeholderVal]}
                  select
                  name={placeholderVal}
                  id={placeholderVal}
                  type="text"
                  variant="outlined"
                  label="Attribute Placeholder"
                  margin="normal"
                  className="w-1/3"
                  onChange={handleHeaderPlaceholders}
                  // onBlur={isValidAutomationRuleName}
                  // helperText={automationNameError ? 'Automation Name cannot be blank.' : ''}
                  // error={automationNameError}
                >
                  {placeHolderFixedFields
                    .filter((option) => {
                      if (selectedLead) {
                        if (selectedLead?.[option.originalLabel]?.length) {
                          return true;
                        }
                        return false;
                      }
                      return true;
                    })
                    .map((option) => {
                      const value = selectedLead?.[option.originalLabel]
                        ? JSON.stringify({
                            value: option.label,
                            placeholder: selectedLead?.[option.originalLabel],
                          })
                        : JSON.stringify({
                            value: option.label,
                            placeholder: option.value,
                          });

                      return (
                        <MenuItem key={option.value} value={value}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </div>
            );
          })}
      </div>
    );
  };

  useEffect(() => {
    const buttonComponent = templateJson?.components?.find(
      (component) => component.type === "BUTTONS"
    );
    if (buttonComponent) {
      const buttonsText = buttonComponent?.buttons
        .filter((button) => button.type === "QUICK_REPLY")
        .map((button) => button.text);
      setQuickReplyButtonCallbackArr(buttonsText);
    }
  }, [templateJson]);
  const getButtonFields = (buttonsArr) => {
    return (
      <div className="flex flex-col justify-between">
        <Typography
          variant="h5"
          className="mt-10"
          gutterBottom
          key="placeholder"
        >
          Template Buttons
        </Typography>

        {buttonsArr.map((eachButton, index) => {
          if (eachButton.type === "QUICK_REPLY") {
            return (
              <div className="flex flex-row mt-4 items-center justify-around">
                <Button
                  variant="contained"
                  className="w-fit mr-9"
                  key={`button-text-${eachButton.text}`}
                  size="small"
                >
                  {eachButton.text}
                </Button>
                <TextField
                  defaultValue={eachButton?.text?.replace(" ", "-")}
                  value={
                    quickReplyButtonCallbackArr[index]?.length
                      ? quickReplyButtonCallbackArr[index]
                      : eachButton?.text?.replace(" ", "-")
                  }
                  type="text"
                  variant="outlined"
                  label={`Callback ID for ${eachButton.text} (Optional)`}
                  margin="normal"
                  className="w-1/2 ml-4"
                  onChange={(e) => handleQuickReplyButtonCallbackId(e, index)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="mx-8">
                        <CopyToClipboard
                          text={
                            quickReplyButtonCallbackArr?.[index]?.length
                              ? quickReplyButtonCallbackArr[index]
                              : eachButton?.text?.replace(" ", "-")
                          }
                        >
                          <Tooltip title="Copy Callback Id">
                            <IconButton
                              aria-label="resetChannel"
                              style={{ color: "blue" }}
                            >
                              <FileCopyOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </CopyToClipboard>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            );
          }
          if (
            eachButton.type === "URL" &&
            getPlaceHolderData(eachButton.url).length > 0
          ) {
            return (
              <>
                <div className="bg-gray-200 rounded-sm p-10 leading-tight whitespace-pre-wrap">
                  <Typography variant="body2" gutterBottom key="body_text">
                    {eachButton.url}
                  </Typography>
                </div>
                <div className="flex flex-row mt-4 items-center">
                  <Button
                    variant="contained"
                    className="w-fit mr-9"
                    key={`button-text-${eachButton.text}`}
                    size="small"
                  >
                    {eachButton.text}
                  </Button>
                  <TextField
                    value={quickReplyButtonCallbackArr[index]}
                    type="text"
                    variant="outlined"
                    label={`Value for {{${index + 1}}}`}
                    margin="normal"
                    required
                    className="w-1/2 ml-4"
                    onChange={(e) => handleQuickReplyButtonCallbackId(e, index)}
                  />
                </div>
              </>
            );
          }
          return "";
        })}
      </div>
    );
  };

  const getPlaceHolderFields = (textString) => {
    const placeholders = /(\{\{[1-9][0-9]?\}\})/g;
    const placeholderArr = textString.match(placeholders);
    return (
      <div>
        <Typography
          variant="h5"
          className="mt-10"
          gutterBottom
          key="placeholder"
        >
          Template Body
        </Typography>
        <div className="bg-gray-200 rounded-sm p-10 leading-tight whitespace-pre-wrap">
          <Typography variant="body2" gutterBottom key="body_text">
            {textString}
          </Typography>
        </div>
        {templateData &&
          placeholderArr &&
          placeholderArr.map((placeholderVal, index) => {
            return (
              <div
                className="grid sm:grid-cols-1 md:grid-cols-3 sm:gap-2 md:gap-4 sm:pt-8"
                key={"placeholder_grid".concat(index)}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  key={"placeholder_grid_item_name".concat(index)}
                  className="self-center"
                >
                  {"Placeholder ".concat(placeholderVal)}
                </Typography>
                <TextField
                  value={bodyPlaceHolderLabel[placeholderVal]}
                  required
                  InputLabelProps={{
                    shrink: bodyPlaceHolderLabel[placeholderVal],
                  }}
                  name={placeholderVal}
                  id={index.toString()}
                  type="text"
                  variant="outlined"
                  label="Placeholder Name"
                  margin="normal"
                  onChange={handleBodyPlaceholders}
                  onBlur={() => isValidPlaceholder(placeholderVal)}
                  helperText={
                    placeholderError[placeholderVal]
                      ? "This Field Cannot be Blank."
                      : ""
                  }
                  error={placeholderError[placeholderVal]}
                />
                <TextField
                  placeholder="Insert Placeholders."
                  value={bodyPlaceHolderLabel[placeholderVal]}
                  select
                  name={placeholderVal}
                  id={placeholderVal}
                  type="text"
                  variant="outlined"
                  label="Attribute PlaceHolders"
                  margin="normal"
                  onChange={handleBodyPlaceholders}
                >
                  {placeHolderFixedFields
                    .filter((option) => {
                      if (selectedLead) {
                        if (selectedLead?.[option.originalLabel]?.length) {
                          return true;
                        }
                        return false;
                      }
                      return true;
                    })
                    .map((option) => {
                      const value = selectedLead?.[option.originalLabel]
                        ? JSON.stringify({
                            value: option.label,
                            placeholder: selectedLead?.[option.originalLabel],
                          })
                        : JSON.stringify({
                            value: option.label,
                            placeholder: option.value,
                          });

                      return (
                        <MenuItem key={option.value} value={value}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </div>
            );
          })}
      </div>
    );
  };

  const [MultimediaHeaderType, setMultimediaHeaderType] = useState("");
  const handleMultiMediaHeaderTypeChange = ({ target: { value, name } }) => {
    setMultimediaHeaderType(value);
  };
  const multimediaHeaderTypeOptions = [
    { label: "File Url", value: "file_url" },
    { label: "Upload File", value: "upload_file" },
    { label: "File Placeholder", value: "file_placeholder" },
  ];

  const [headerMultimediaUrl, setHeaderMultimediaUrl] = useState("");
  const handleMultimediaUrlChange = ({ target: { value } }) => {
    const finalUrl = /^https?:\/\//.test(value) ? value : null;
    setHeaderMultimediaUrl(value);
    setMultiMedia(finalUrl);
  };

  const [headerMultimediaPlacholder, setHeaderMultimediaPlacholder] =
    useState("{image_url}");
  const handleMultimediaPlaceholderChange = ({ target: { value } }) => {
    setHeaderMultimediaPlacholder(value);
    setMultiMedia(value);
  };

  useEffect(() => {
    setMultiMedia(null);
    setHeaderMultimediaUrl(null);
    if (headerMultimediaPlacholder?.length) {
      setMultiMedia(headerMultimediaPlacholder);
    }
  }, [MultimediaHeaderType]);

  const renderUi = (componentData, index) => {
    switch (componentData.type) {
      case "HEADER":
        return (
          <div key={`header_main_${index}`}>
            <Typography variant="h5" gutterBottom key="header">
              Template Header
            </Typography>
            {componentData.format === "TEXT" && (
              <div className="bg-gray-200 rounded-sm p-10">
                <Typography variant="body2" gutterBottom key="body_text">
                  {componentData.text}
                </Typography>
              </div>
            )}
            {componentData.format === "TEXT" && (
              <div className="header_placeholder_main">
                {getHeaderPlaceHolderFields(componentData.text)}
              </div>
            )}
            {componentData.format !== "TEXT" && (
              <>
                <Typography
                  variant="body1"
                  gutterBottom
                  key="header_media_name"
                >
                  {getMediaType(componentData.format)}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  className="p-4 my-10"
                  key="header_grid"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className="flex justify-between items-center"
                    key="header_grid_item"
                  >
                    <TextField
                      placeholder="Insert Placeholder"
                      value={MultimediaHeaderType}
                      select
                      name="multimedia-header-type"
                      type="text"
                      variant="outlined"
                      label="Select Multimedia Header Type"
                      margin="normal"
                      onChange={handleMultiMediaHeaderTypeChange}
                      fullWidth
                    >
                      {multimediaHeaderTypeOptions.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className="flex justify-between items-center"
                    key="header_grid_item"
                  >
                    {MultimediaHeaderType === "upload_file" && (
                      <>
                        <div key="header_file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={() => setShowAttachmentPopup(true)}
                          >
                            <span className="material-icons mr-10">
                              publish
                            </span>
                            Choose Files
                          </Button>
                        </div>
                        {multiMedia && (
                          <Typography
                            variant="caption"
                            gutterBottom
                            key="header_media_name_source"
                          >
                            {multiMedia.file_source_name}
                          </Typography>
                        )}
                      </>
                    )}
                    {MultimediaHeaderType === "file_placeholder" && (
                      <TextField
                        placeholder="{your-variable-name}"
                        name="image-url-placeholder"
                        type="text"
                        variant="outlined"
                        label="Image Placeholder"
                        margin="normal"
                        value={headerMultimediaPlacholder}
                        onChange={handleMultimediaPlaceholderChange}
                        fullWidth
                      />
                    )}
                    {MultimediaHeaderType === "file_url" && (
                      <TextField
                        placeholder="Please enter valid image url."
                        name="image-url"
                        type="text"
                        variant="outlined"
                        label="Image Url"
                        margin="normal"
                        value={headerMultimediaUrl}
                        onChange={handleMultimediaUrlChange}
                        fullWidth
                      />
                    )}
                  </Grid>
                  {headerError && (
                    <FormHelperText className="text-red">
                      Please upload a file.
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
          </div>
        );
      case "BODY":
        return (
          <div key={`placeholder_main_${index}`}>
            {getPlaceHolderFields(componentData.text)}
          </div>
        );
      case "FOOTER":
        return (
          <div key={`footer_main_${index}`} className="mt-10">
            <Typography variant="h5" gutterBottom key="placeholder">
              Template Footer
            </Typography>
            <div className="bg-gray-200 rounded-sm p-10">
              <Typography variant="body2" gutterBottom key="body_text">
                {componentData.text}
              </Typography>
            </div>
          </div>
        );
      case "BUTTONS":
        {
          const havingQuickReply = componentData.buttons.some((button) => {
            return button.type === "QUICK_REPLY";
          });

          if (havingQuickReply) {
            return (
              <div key={`button_main_${index}`}>
                {getButtonFields(componentData.buttons)}
              </div>
            );
          }
          if (!havingQuickReply) {
            let havingDynamicData = false;
            const havingDynamicReply = componentData.buttons.some((button) => {
              if (button.url) {
                const dynamicDataArr = getPlaceHolderData(button.url);
                if (dynamicDataArr && dynamicDataArr.length > 0) {
                  havingDynamicData = true;
                }
              }
              return button.type === "URL";
            });
            if (havingDynamicReply && havingDynamicData) {
              return (
                <div key={`button_main_${index}`}>
                  {getButtonFields(componentData.buttons)}
                </div>
              );
            }
          }
        }
        break;
      default:
        break;
    }
    return "";
  };

  const handleAutoReplyTemplateJson = (messageJsonData) => {
    const stepData = {};
    stepData.whatsapp_template = messageJsonData;
    props?.handleStepData(stepData);
  };

  const handleTemplateChange = (event, value) => {
    if (value) {
      setSelectedTemplated(value);
      setTemplate(value.value);
      setQuickReplyButtonCallbackArr([]);
    }
  };

  useEffect(() => {
    if (template.length) {
      setTemplateError(false);
    } else {
      setTemplateError(true);
    }
  }, [template]);

  const validateTemplate = () => {
    if (
      (headerConsist === true &&
        multiMedia === null &&
        headerMediaType !== "text") ||
      validatePlaceholders() ||
      (headerConsist === true &&
        headerMediaType === "text" &&
        validateHeaderPlaceholders())
    ) {
      headerConsist === true &&
      multiMedia === null &&
      headerMediaType !== "text"
        ? setHeaderError(true)
        : setHeaderError(false);
    } else {
      props?.handleFinalJson
        ? props?.handleFinalJson(finalTemplateJson)
        : handleAutoReplyTemplateJson(finalTemplateJson);
    }
  };

  useEffect(() => {
    if (!template.length) {
      setTemplateError(true);
    } else {
      validateTemplate();
    }
  }, [props.validateEmmaTemplate]);

  return (
    <Grid>
      <Grid item xs={12} sm={12} className="px-2">
        {templatesArr.length > 0 && (
          <Autocomplete
            disableClearable
            value={selectedTemplated}
            onChange={handleTemplateChange}
            options={templatesArr}
            getOptionLabel={(option) => option?.label}
            getOptionSelected={(option, val) => option.value === val.value}
            onBlur={isValidTemplateName}
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                variant="outlined"
                label="Select Template"
                margin="normal"
                fullWidth
                className="mb-4 mt-4"
                helperText={templateError ? "Please Select Template" : ""}
                error={templateError || !selectedTemplated.value?.length}
              />
            )}
          />
        )}
        {templateJson &&
          templateJson.components.map((componentData, index) => {
            return renderUi(componentData, index);
          })}
      </Grid>

      {finalTemplateJson && (
        <Grid item xs={12} sm={12} className="px-4">
          <Typography variant="h5" gutterBottom key="header">
            Preview
          </Typography>
          <div className="leading-tight whitespace-pre-wrap">
            <TemplateMessage
              template={finalTemplateJson}
              whatsappTemplateArr={templatesDataArr}
            />
          </div>
          {/* <CopyToClipboard text={JSON.stringify(finalTemplateJson)}>
            <Button
              fullWidth
              className="mt-16"
              variant="outlined"
              color="primary"
            >
              Copy Template JSON
            </Button>
          </CopyToClipboard> */}
        </Grid>
      )}

      {showAttachmentPopup && (
        <AddAttachmentPopup
          open={showAttachmentPopup}
          closeDialog={() => {
            setShowAttachmentPopup(false);
          }}
          setMediaFile={setMultiMedia}
          mediaFile={multiMedia}
          isSingleFile={Boolean(true)}
          onlyImage={
            headerMediaType === "image" ? Boolean(true) : Boolean(false)
          }
          onlyVideo={
            headerMediaType === "video" ? Boolean(true) : Boolean(false)
          }
        />
      )}
    </Grid>
  );
};

export default EmmaTemplatePopup;
