import React from "react";

function App() {
  return (
    <div className="App">
      Empower your business by chat directly with your Leads.
    </div>
  );
}

export default App;
