import React, { useEffect } from "react";
import CreateChannelForm from "./CreateChannelForm";
import { getAllChannels, loginUser } from "../services/AuthService";
import jwt_decode from "jwt-decode";
import Channels from "./Channels";
import {
  authAction,
  channelsAction,
  organisationAction,
  settings,
} from "./settingSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import CircularIndeterminate from "../ChatApp/circular-progress";

const ZOHO = window.ZOHO || {};
const Setting = () => {
  const settingData = useSelector(settings);

  const {
    auth: { authToken },
    channels,
  } = settingData;

  const dispatch = useDispatch();

  useEffect(() => {
    async function initialise() {
      await ZOHO.embeddedApp.init();
      const org = await ZOHO.CRM.CONFIG.getOrgInfo();
      const currentUser = await ZOHO.CRM.CONFIG.getCurrentUser();

      const response = await loginUser({
        userName: currentUser?.users[0].full_name,
        orgId: org?.org[0].id,
      });
      localStorage.setItem("jwt_token", response?.token);
      const decodedToken = jwt_decode(response?.token);
      const channelRes = await getAllChannels(response?.token);
      dispatch(organisationAction({ organisation: org?.org[0] }));
      dispatch(channelsAction({ channels: channelRes?.data }));
      dispatch(authAction({ user: decodedToken, authToken: response?.token }));
    }
    initialise();
  }, [dispatch]);

  return (
    <div className="flex flex-col p-12 overflow-hidden">
      <div className="flex justify-between mb-16 items-center">
        <h1>Whatsapp Chat Settings</h1>
        <Button variant="outlined">
          <a
            href="https://docs.rampwin.com/more-how-tos/setup-zoho-crm-extension"
            className="no-underline"
            target="_blank"
            rel="noreferrer"
          >
            Help Guide
          </a>
        </Button>
      </div>
      {authToken ? (
        <>{channels?.length > 0 ? <Channels /> : <CreateChannelForm />}</>
      ) : (
        <div className="flex flex-1 h-full items-center justify-center m-20">
          <CircularIndeterminate />
        </div>
      )}
    </div>
  );
};

export default Setting;
