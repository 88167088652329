import format from "date-fns/format";
import DoneIcon from "@material-ui/icons/Done";
import {
  DoneAll,
  ScheduleOutlined,
  SmsFailedOutlined,
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import lookup from "country-code-lookup";
import { phone } from "phone";

export function isToday(someDate) {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
}

export function showDateTime(someDate, showTime) {
  const dateObj = new Date(someDate);
  if (isToday(dateObj)) {
    return format(dateObj, "HH:mm");
  }
  return format(dateObj, `M/dd/yy${showTime ? " HH:mm" : ""}`);
}

export function renderReadIcon(item) {
  if (item.send_status === "failed") {
    return (
      <Tooltip title={item.message_vendor_verdict || "Failed"}>
        <SmsFailedOutlined className="pl-1" style={{ color: "red" }} />
      </Tooltip>
    );
  }
  switch (item.read_status) {
    case "viewed":
      return <DoneAll className="pl-1" style={{ color: "#87CEEB" }} />;
    case "delivered":
      return <DoneAll className="pl-1" />;
    case "sent":
      return <DoneIcon className="pl-1" />;
    case "triggered":
      return <ScheduleOutlined className="pl-1" />;
    default:
      return <></>;
  }
}

export const zohoPlaceholders = {
  default: [
    "phone_number",
    "First_Name",
    "Last_Name",
    "Full_Name",
    "Mobile",
    "Phone",
    "Fax",
    "Email",
    "Secondary_Email",
    "Company",
    "City",
    "State",
    "Street",
    "Country",
    "Zip_Code",
    "Twitter",
    "Website",
    "Designation",
    "Description",
    "Industry",
    "Lead_Source",
    "Lead_Status",
    "Rating",
    "Record_Image",
    "Salutation",
    "Skype_ID",
    "Annual_Revenue",
  ],
};

export const snakeCaseToTitleCase = (string) =>
  string
    ?.replace(/^[-_]*(.)/, (_, c) => c?.toUpperCase())
    ?.replace(/[-_]+(.)/g, (_, c) => ` ${c?.toUpperCase()}`);

export const titleCaseToSnakeCase = (s) =>
  s
    .replace(/ +/g, "")
    .replace(/\.?([A-Z])/g, (x, y) => {
      return `_${y.toLowerCase()}`;
    })
    .replace(/^_/, "");

export const titleCase = (s) =>
  s
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getFormattedPhone = (country, phoneNum) => {
  try {
    const formattedCountry = lookup?.byCountry(country);
    let formattedPhone;
    if (formattedCountry) {
      const res = phone(phoneNum, { country: formattedCountry.iso3 });
      if (res?.isValid) {
        formattedPhone = res?.phoneNumber.replace(/([^\w ]|_)/g, "");
        formattedPhone = formattedPhone.replace(/ /g, "");
      }
    }
    return formattedPhone;
  } catch (error) {
    console.log("Error in getFormattedCountry :", error);
  }
};
