import { configureStore } from "@reduxjs/toolkit";
import settingReducer from "./Setting/settingSlice";
import chatSlice from "./ChatApp/chatSlice";

export const store = configureStore({
  reducer: {
    settings: settingReducer,
    chats: chatSlice,
  },
});
