import { officalTemplatesAction, settings } from "../Setting/settingSlice";
import { chats } from "./chatSlice";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import EmmaTemplatePopup from "./EmmaTemplatePopup";
import { renderReadIcon, showDateTime } from "../common";
import clsx from "clsx";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import Linkify from "linkify-react";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import "react-image-lightbox/style.css";
import { useDispatch, useSelector } from "react-redux";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import { SocketContext } from "../../socketContext";
import ChatMediaItem from "./ChatMediaItem";
import TemplateMessage from "./TemplateMessage";
import { syncOfficialTemplate } from "../services/AuthService";
import { chatUploadFiles, getChatMessages } from "../services/ChatService";
import SendIcon from "@material-ui/icons/Send";
import AddAttachmentPopup from "./AddAttachmentPopup";
import ThumbImage from "./ChatThumbFile";
import { Tooltip } from "@material-ui/core";
import PdfDocument from "./PdfDocument";

const useStyles = makeStyles((theme) => ({
  messageRow: {
    "&.contact": {
      "& .bubble": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        minWidth: 100,
        "& .time": {
          marginLeft: 12,
          justifyContent: "flex-end",
          left: 0,
        },
      },
      "& .vcard": {
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        minWidth: 100,
        "& .time": {
          marginLeft: 12,
          justifyContent: "flex-end",
          left: 0,
        },
      },
      "&.first-of-group": {
        "& .bubble": {
          borderTopLeftRadius: 10,
        },
      },
      "&.last-of-group": {
        "& .bubble": {
          borderBottomLeftRadius: 10,
        },
      },
    },
    "&.me": {
      paddingLeft: 40,

      "& .avatar": {
        order: 2,
        margin: "0 0 0 16px",
      },
      "& .bubble": {
        marginLeft: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        minWidth: 100,
        "& .time": {
          justifyContent: "flex-end",
          right: 0,
          // marginRight: 12
        },
      },
      "& .vcard": {
        marginLeft: "auto",
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        minWidth: 100,
        "& .time": {
          justifyContent: "flex-end",
          right: 0,
          // marginRight: 12
        },
      },
      "&.first-of-group": {
        "& .bubble": {
          borderTopRightRadius: 10,
        },
      },

      "&.last-of-group": {
        "& .bubble": {
          borderBottomRightRadius: 10,
        },
      },
    },
    // '&.contact + .me, &.me + .contact': {
    // 	paddingTop: 20,
    // 	marginTop: 20
    // },
    "&.first-of-group": {
      "& .bubble": {
        borderTopLeftRadius: 20,
        paddingTop: 13,
      },
    },
    "&.last-of-group": {
      "& .bubble": {
        borderBottomLeftRadius: 20,
        paddingBottom: 13,
        "& .time": {
          display: "flex",
        },
      },
    },
  },
  viewAllContactCard: {
    boxShadow: "0 0 0.25rem rgba(0, 0, 0, 0.3)",
    marginTop: "20px",
    borderRadius: "0",
    maxWidth: "200px",
  },
  viewAllContactCardContent: {
    paddingBottom: "16px!important",
    position: "relative",
  },
  contactName: {
    fontSize: "1em",
    color: "#333",
  },
  viewAllButton: {
    borderRadius: "0!important",
    border: "none",
    borderTop: "2px solid #e6e6e6",
  },
  error: {
    color: "red",
  },
  mediaFileRoot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  mediaFileGridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

const Chat = (props) => {
  const s3URL = process.env.REACT_APP_BASE_S3_URL;
  const socket = useContext(SocketContext).getSocketInstance();
  const settingData = useSelector(settings);
  const chatsData = useSelector(chats);
  // const [photoIndex, setPhotoIndex] = useState(0);
  // const [expanded, setExpanded] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [mediaFile, setMediaFile] = useState([]);
  const [messageTextDisplay, setMessagetextDisplay] = useState("");
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [userTyping, setUserTyping] = useState(null);
  const [showWindowAlert, setShowWindowAlert] = useState(false);
  const [showMediaLoader] = useState(false);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [openEmmaTemplateDialog, setOpenEmmaTemplateDialog] = useState(false);
  const [validateEmmaTemplate, setValidateEmmaTemplate] = useState(false);
  const [fetchedMessages, setFetchedMessages] = useState([]);
  const [allChatMessage, setAllChatMessage] = useState([]);
  const [chatPageNum, setChatPageNum] = useState(1);
  const [maxPages, setMaxPages] = useState(1);

  //To Add logic
  const [chatEnabled] = useState(true);
  const [beforeMaxContactsLimit] = useState(true);

  const chatMessageDataLengthLatest = useRef();
  let chatMessageDataLength = allChatMessage?.length;
  chatMessageDataLengthLatest.current = chatMessageDataLength;
  const dispatch = useDispatch();
  const {
    auth: { user, authToken },
    channels,
    officalTemplates: whatsappTemplateArr,
  } = settingData;

  const { contacts } = chatsData;

  const selectedContactDetails = contacts[0];
  const selectedChannelsDetails = channels[0];

  const handleNewMessageArrived = (eventJson) => {
    const toSetMessages = [...allChatMessage, { ...eventJson?.message }];
    setAllChatMessage(toSetMessages);
  };

  const handleMessageStatusChange = (eventJson) => {
    if (eventJson.integration.id === selectedChannelsDetails?._id) {
      if (eventJson.contact.id === selectedContactDetails?._id) {
        const allMsg = [...allChatMessage];
        const existingMsgIndex = allMsg.findIndex(
          (o) => o._id === eventJson._id
        );
        if (existingMsgIndex !== -1) {
          allMsg[existingMsgIndex].read_status = eventJson.read_status;
          allMsg[existingMsgIndex].send_status = eventJson.send_status;
          allMsg[existingMsgIndex].message_vendor_verdict =
            eventJson.message_vendor_verdict;

          setAllChatMessage(allMsg);
        }
      }
      // if (existingContactIndex !== -1) {
      //   if (allContacts[existingContactIndex].last_message.message_id === eventJson._id) {
      //     // It means the contact already exist in listed contacts.
      //     // Just move it on top
      //     const contacts = [...allContacts];
      //     contacts[existingContactIndex].last_message.read_status = eventJson.read_status;
      //     contacts[existingContactIndex].last_message.send_status = eventJson.send_status;
      //     setAllContacts(contacts);
      //   }
    }
  };

  useEffect(() => {
    const newMessageHandler = (eventJson) => {
      handleNewMessageArrived(eventJson);
    };

    const messageStatusChangeHandler = (eventJson) => {
      handleMessageStatusChange(eventJson);
    };

    socket.on("new_message_arrived", newMessageHandler);
    socket.on("message_read_change", messageStatusChangeHandler);
    // socket.on("mark_as_read", incomingMarkAsReadHandler);
    return () => {
      socket.off("new_message_arrived", newMessageHandler);
      socket.off("message_read_change", messageStatusChangeHandler);
      // socket.off("mark_as_read", incomingMarkAsReadHandler);
    };
  }, [allChatMessage]);

  useEffect(() => {
    async function callAllChatMessage() {
      const searchFilter = [
        {
          field_name: "contact.id",
          operator: "matches",
          field_value: selectedContactDetails?._id,
        },
        {
          field_name: "integration.id",
          operator: "matches",
          field_value: selectedChannelsDetails?._id,
        },
        {
          field_name: "send_status",
          operator: "in",
          field_value: ["sent", "deleted", "failed", "called"],
        },
      ];
      const toSendParams = {
        search: JSON.stringify(searchFilter),
        show_page_count: "yes",
      };
      const chatRes = await getChatMessages(
        chatPageNum,
        authToken,
        toSendParams
      );
      const toSetMessage = [
        ...JSON.parse(JSON.stringify(fetchedMessages)),
        ...JSON.parse(JSON.stringify(chatRes?.data)),
      ];
      setFetchedMessages(toSetMessage);
      setMaxPages(chatRes?.pages);
    }
    callAllChatMessage();
  }, [chatPageNum]);

  useEffect(() => {
    async function arrangeChatMessage() {
      const allData = [];
      if (fetchedMessages && fetchedMessages.length) {
        fetchedMessages.forEach((mess) => {
          if (
            mess.integration.id === selectedChannelsDetails?._id &&
            mess.contact.id === selectedContactDetails?._id
          ) {
            allData.unshift(mess);
          }
        });
        setAllChatMessage(allData);
      }
    }
    arrangeChatMessage();
  }, [fetchedMessages]);

  useEffect(() => {
    async function callFunc() {
      const res = await syncOfficialTemplate(channels[0]?._id, authToken);
      if (res?.success) {
        dispatch(officalTemplatesAction({ templates: res }));
      }
    }
    callFunc();
  }, [dispatch]);

  let timeoutObj;
  useEffect(() => {
    const typingStartHandler = (eventJson) => {
      if (
        eventJson.contact.id === selectedContactDetails?._id &&
        eventJson.integration.id === selectedChannelsDetails?._id &&
        eventJson.user.id !== user?.id
      ) {
        setUserTyping(eventJson.user.full_name);
        clearTimeout(timeoutObj);
        timeoutObj = setTimeout(() => {
          setUserTyping(null);
        }, 3000);
      }
    };
    socket.on("teammate_typing", typingStartHandler);
    return () => {
      socket.off("teammate_typing", typingStartHandler);
    };
  }, [selectedContactDetails?._id, selectedChannelsDetails?._id]);

  useEffect(() => {
    let firstIncomingMsg;
    if (allChatMessage) {
      let found = false;
      allChatMessage
        .slice()
        .reverse()
        .forEach((message) => {
          if (message.direction === "i" && !found) {
            firstIncomingMsg = message;
            found = true;
          }
        });
      if (firstIncomingMsg) {
        if (moment().diff(moment(firstIncomingMsg.createdAt), "hours") >= 24) {
          setShowWindowAlert(true);
        } else {
          setShowWindowAlert(false);
        }
      } else if (chatMessageDataLengthLatest.current === 0) {
        setShowWindowAlert(true);
      }
    }
  }, [selectedContactDetails, allChatMessage]);

  const features = {
    reply: true,
    forward: false,
    readReceipts: true,
    delete: false,
    onlyImageAllowed: false,
    media: true,
    dripCampaign: true,
    shareContact: true,
  };
  const classes = useStyles(props);
  const chatRef = useRef(null);

  const quickReplyData = [];

  const itemarr = [];

  allChatMessage?.forEach((item) => {
    if (item.message.type === "image" && item.send_status !== "deleted") {
      itemarr[itemarr.length] = item.message;
    }
  });

  const handleExpandClickOpen = (selitem) => {
    // setSelectedImage(selitem.message);
    if (selitem.message.type === "image") {
      // setExpanded(true);

      itemarr.forEach((item, index) => {
        if (selitem.message.id === item.id) {
          // setPhotoIndex(index);
        }
      });
    }
  };

  function renderMessage(item) {
    if (item.send_status === "deleted") {
      //   return <DeletedChatTemplate />;
      console.log("deleted");
    }
    switch (item.message.type) {
      case "text":
        return (
          <div
            className="leading-tight whitespace-pre-wrap break-all"
            id={item._id}
            style={{
              maxWidth: 400,
            }}
          >
            {selectedContactDetails?.contact_type === "group" &&
              item.direction === "i" &&
              item.sender?.name && (
                <Typography className="mb-4">
                  <Box fontWeight="fontWeightBold">{item.sender.name}</Box>
                </Typography>
              )}
            {/* {item.quoted && (
              <ChatReplyQuoted
                quotedMsg={item.quoted}
                handleClick={() => handleScroll(item.quoted.id)}
                whatsappTemplateArr={whatsappTemplateArr}
              />
            )} */}
            {item.direction === "o" &&
            (selectedContactDetails?.contact_type === "user" ||
              (selectedContactDetails?.contact_type === "group" &&
                !selectedContactDetails?.participants)) ? (
              <Linkify
                options={{
                  attributes: {
                    style: {
                      color: "white",
                      wordBreak: "break-all",
                      border: "0px",
                      textDecoration: "underline",
                      backgroundColor: "transparent",
                    },
                  },
                  target: {
                    url: "_blank",
                  },
                  className: "break-all",
                  // className: 'hover:underline hover:underline hover:bg-transparent'
                }}
              >
                {item.message.text}
              </Linkify>
            ) : (
              (selectedContactDetails?.contact_type === "user" ||
                (selectedContactDetails?.contact_type === "group" &&
                  !selectedContactDetails?.participants)) && (
                <Linkify
                  options={{
                    attributes: {
                      style: {
                        color: "blue",
                        wordBreak: "break-all",
                        border: "0px",
                        textDecoration: "underline",
                        backgroundColor: "transparent",
                      },
                    },
                    target: {
                      url: "_blank",
                    },
                    className: "break-all",
                  }}
                >
                  {item.message.text}
                </Linkify>
              )
            )}
          </div>
        );
      case "image":
      case "sticker":
      case "video":
      case "audio":
      case "voice":
      case "ptt":
      case "story_mention":
      case "share":
        return (
          <div
            className="leading-tight whitespace-pre-wrap"
            // onClick={() => handleExpandClickOpen(item)}
            // onKeyDown={() => handleExpandClickOpen(item)}
            aria-hidden="true"
          >
            {selectedContactDetails.contact_type === "group" &&
              item.direction === "i" && (
                <Typography className=" ml-12 mt-12 mb-4">
                  <Box fontWeight="fontWeightBold">{item?.sender?.name}</Box>
                </Typography>
              )}
            {/* {item.quoted && (
              <ChatReplyQuoted
                extraCss="m-10 mr-20"
                quotedMsg={item.quoted}
                handleClick={() => handleScroll(item.quoted.id)}
                whatsappTemplateArr={whatsappTemplateArr}
              />
            )} */}
            <ChatMediaItem
              item={item}
              selectedContactDetails={selectedContactDetails}
              openMedia={handleExpandClickOpen}
            />
          </div>
        );
      // case 'vcard':
      // if (item?.message?.vcardList?.length) {
      //   return (
      //     <Card className={classes.viewAllContactCard}>
      //       <CardContent className={classes.viewAllContactCardContent}>
      //         <Typography
      //           variant="h6"
      //           component="h2"
      //           className={classes.contactName}
      //         >
      //           {item.message.vcardList.length > 1
      //             ? `${item.message.vcardList[0].name || "Unknown"} and ${
      //                 item.message.vcardList.length - 1
      //               } more`
      //             : `${item.message.vcardList[0].name || "Unknown"}`}
      //         </Typography>
      //       </CardContent>
      //       {item.message.vcardList.length > 1 ? (
      //         <Button
      //           onClick={(e) => {
      //             setOpenAddContactSidebar(true);
      //             setVCardDataList(item.message.vcardList);
      //           }}
      //           className={`${classes.viewAllButton} w-full`}
      //           color="primary"
      //         >
      //           View All
      //         </Button>
      //       ) : (
      //         <Button
      //           className={`${classes.viewAllButton} w-full`}
      //           color="primary"
      //           onClick={(e) => {
      //             item?.message?.vcardList.map((innerItem) => {
      //               const contactDetails = {
      //                 phone_number: innerItem.phone,
      //                 full_name: innerItem.name,
      //               };
      //               dispatch(addContact(contactDetails));
      //               return 0;
      //             });
      //           }}
      //         >
      //           Add
      //         </Button>
      //       )}
      //     </Card>
      //   );
      // }
      // break;
      case "location":
        return (
          <a
            href={item.message.text}
            target="_blank"
            rel="noreferrer"
            className="p-5 m-2"
          >
            {item.message.text}
          </a>
        );
      case "template": {
        return item.message.template ? (
          <div className="leading-tight whitespace-pre-wrap" id={item._id}>
            <TemplateMessage
              template={item.message.template}
              whatsappTemplateArr={whatsappTemplateArr}
            />
          </div>
        ) : (
          <Typography className="m-12">{item.message.caption}</Typography>
        );
      }
      // case 'interactive':
      //     return item.direction === 'i' ? (
      //         <div className="m-12 leading-tight whitespace-pre-wrap">
      //             {item.quoted && item.quoted.interactive && (
      //                 <ChatReplyQuoted quotedMsg={item.quoted} handleClick={() => handleScroll(item.quoted.id)} />
      //             )}
      //             {item.quoted && item.quoted.template && (
      //                 <ChatReplyQuoted
      //                     quotedMsg={item.quoted}
      //                     handleClick={() => handleScroll(item.quoted.id)}
      //                     whatsappTemplateArr={whatsappTemplateArr}
      //                 />
      //             )}

      //             <Typography className="mb-4">
      //                 {item.integration.channel_type === 'facebook'
      //                     ? item.message.text
      //                     : item.message.interactive.title}
      //             </Typography>

      //             {item.message.interactive.description && (
      //                 <Typography className="mb-4">{item.message.interactive.description}</Typography>
      //             )}
      //         </div>
      //     ) : (
      //         <WhatsappInteractiveMessage template={item.message.interactive} />
      //     );
      // case 'facebook_payload':
      //     return <FacebookInteractiveMessage template={item.message.facebook_payload} />;
      // case 'order':
      //     return (
      //         <CartOrder
      //             item={item}
      //             canCreateManualPaymentLink={canCreateManualPaymentLink}
      //             sendPaymentLink={sendPaymentLink}
      //         />
      //     );
      // case 'referred_product':
      //     return <ReferredProduct item={item} />;
      case "document":
      case "file":
        return (
          <div className="leading-tight whitespace-pre-wrap">
            {/* {item.quoted && (
              <ChatReplyQuoted
                quotedMsg={item.quoted}
                handleClick={() => handleScroll(item.quoted.id)}
                whatsappTemplateArr={whatsappTemplateArr}
              />
            )} */}
            <PdfDocument item={item} />
          </div>
        );
      // case 'call': {
      //     const messageData = item.message;
      //     return (
      //         <Card className="bg-transparent">
      //             <CardContent className="rounded-none bg-transparent text-white">
      //                 <div className="flex items-center mb-8">
      //                     <CallMadeIcon />
      //                     <Typography>Outgoing Call</Typography>
      //                 </div>
      //                 {item?.read_status && (
      //                     <div className="mb-8">
      //                         <strong>Status: </strong> {snakeCaseToTitleCase(item.read_status)}
      //                     </div>
      //                 )}
      //                 {messageData?.desposition?.type && (
      //                     <div className="mb-8">
      //                         <strong>Desposition: </strong>{' '}
      //                         {snakeCaseToTitleCase(messageData?.desposition?.type)}
      //                     </div>
      //                 )}
      //                 {messageData?.desposition?.note && (
      //                     <div>
      //                         <strong>Notes: </strong> {snakeCaseToTitleCase(messageData?.desposition?.note)}
      //                     </div>
      //                 )}
      //             </CardContent>
      //         </Card>
      //     );
      // }
      default:
        return (
          <div className="p-8">
            <div style={{ fontStyle: "italic", color: "primary" }}>
              <Typography>
                <ErrorOutline />
                This message type is not supported
              </Typography>
            </div>
          </div>
        );
    }
  }

  function handleFileRemove(ev, fileIndex) {
    mediaFile.splice(fileIndex, 1);
    setMediaFile([...mediaFile]);
  }

  function onMessageSubmit(ev) {
    ev.preventDefault();
    let message = `${messageText}`;
    if (message === "" && mediaFile?.length === 0) {
      return;
    }
    let eventJson = {};
    //   showReplyToBox && selectedMessage
    //     ? {
    //         reply_to: selectedMessage._id,
    //       }
    //     : {};

    if (mediaFile?.length > 0) {
      //   if (["instagram", "facebook"].includes(selectedChannelName)) {
      //     // If media is attached and channel is Facebook/Instagram, it may take some time to send message.
      //     // So enable a time triggerd yellow message.
      //     setShowMediaTimeTakingMessage(true);
      //     setTimeout(() => {
      //       setShowMediaTimeTakingMessage(false);
      //     }, 8000);
      //   }

      eventJson = {
        type: "file",
        // text: message,
        contact_id: selectedContactDetails?._id,
        integration_id: selectedChannelsDetails?._id,
        files: mediaFile,
        ...eventJson,
      };
      !features.noTextWithImage && (eventJson.text = message);
    } else {
      eventJson = {
        type: "text",
        text: message,
        contact_id: selectedContactDetails?._id,
        integration_id: selectedChannelsDetails?._id,
        ...eventJson,
      };
    }

    socket.emit("send_message", eventJson);
    setMediaFile([]);
    setMessageText("");
    setMessagetextDisplay("");
    setEmojiPicker(false);
    // setShowReplyToBox(false);
    // setSelectedMessage(null);
    // scrollToBottom();
  }

  function addEmoji(e) {
    const emoji = e.native;
    if (quickReplyData.length > 0) {
      setMessagetextDisplay(messageTextDisplay + emoji);
      setMessageText(messageText + emoji);
    } else {
      setMessageText(messageText + emoji);
    }
  }

  function onInputChange(event, newValue, newPlainTextValue, mention) {
    if (quickReplyData?.length > 0) {
      //   setMentionsArr(mention);
      setMessagetextDisplay(newValue);
      setMessageText(newPlainTextValue);
    } else {
      setMessageText(event.target.value);
    }
    socket.emit("typing_start", {
      contact_id: selectedContactDetails?._id,
      integration_id: selectedChannelsDetails?._id,
    });
  }

  //   function onInputkeyPress(ev) {
  //     const code = ev.code ? ev.code : ev.which;
  //     const key = ev.keyCode;
  //     if (
  //       //   enterToSend &&
  //       (code === "Enter" || (key === 13 && ev.location === 3)) &&
  //       !ev.shiftKey &&
  //       chatEnabled &&
  //       beforeMaxContactsLimit
  //     ) {
  //       onMessageSubmit(ev);
  //     }
  //   }

  function handleMediaPaste({ clipboardData: { files } }) {
    const filesData = [];
    if (files.length > 0) {
      Object.entries(files).forEach(([key, value]) => {
        filesData.push(value);
      });

      Promise.all(
        filesData?.map((file) => {
          return chatUploadFiles(file, null, authToken);
        })
      ).then((responses) => {
        const uploadedMediaFiles = responses.map(
          (eachResponse) => eachResponse.data
        );
        setMediaFile([...mediaFile, ...uploadedMediaFiles]);
      });
    }
  }

  const emmaTemplateJson = (messageJsonData) => {
    const eventJson = {
      type: "template",
      template: messageJsonData,
      contact_id: selectedContactDetails?._id,
      integration_id: selectedChannelsDetails?._id,
    };
    socket.emit("send_message", eventJson);
    setOpenEmmaTemplateDialog(false);
  };

  const renderMediaFileThumbs = (eachFile, fileIndex) => {
    if (eachFile.file_mime_type.startsWith("image")) {
      const finalUrl = /^https?:\/\//.test(eachFile.file_s3_path)
        ? eachFile.file_s3_path
        : `${s3URL}${eachFile.file_s3_path}`;
      return (
        <ThumbImage
          showClose={Boolean(true)}
          key={fileIndex}
          fileUrl={finalUrl}
          onClick={(ev) => handleFileRemove(ev, fileIndex)}
        />
      );
    }
    if (eachFile.file_mime_type.startsWith("video")) {
      return (
        <ThumbImage
          key={fileIndex}
          type="video"
          showClose={Boolean(true)}
          onClick={(ev) => handleFileRemove(ev, fileIndex)}
        />
      );
    }
    if (eachFile.file_mime_type.startsWith("audio")) {
      return (
        <ThumbImage
          key={fileIndex}
          type="audio"
          showClose={Boolean(true)}
          onClick={(ev) => handleFileRemove(ev, fileIndex)}
        />
      );
    }
    return (
      <ThumbImage
        key={fileIndex}
        type="others"
        showClose={Boolean(true)}
        onClick={(ev) => handleFileRemove(ev, fileIndex)}
      />
    );
  };

  const isChatNextPagePresent = () => {
    let result = false;
    if (chatPageNum < maxPages) {
      result = true;
    }
    return result;
  };

  function loadMoreMessages() {
    setChatPageNum(1 + chatPageNum);
  }

  return (
    <div
      className={clsx(
        "flex flex-col justify-between relative max-width-full min-h-[90vh]",
        props.className
      )}
    >
      <div
        id="chatContainer"
        className="relative flex max-w-full flex-col overflow-y-auto transition-all h-full"
      >
        {isChatNextPagePresent() && (
          <Button
            onClick={() => {
              loadMoreMessages();
            }}
            size="small"
            startIcon={<RotateLeftIcon />}
          >
            Load more
          </Button>
        )}

        {allChatMessage && allChatMessage?.length > 0 ? (
          <div
            ref={chatRef}
            className="max-w-full relative flex flex-col overflow-y-auto pt-4 px-4 ltr:pl-4 rtl:pr-56"
          >
            {allChatMessage.map((item, i) => {
              return (
                selectedContactDetails._id === item?.contact?.id && (
                  <div
                    style={{ marginBottom: "35px" }}
                    key={item._id}
                    id={item.message.id}
                    className={clsx(
                      classes.messageRow,
                      "flex flex-col flex-grow-0 flex-shrink-0 items-start justify-end relative pb-4 mb-16",     
                      { me: item?.direction === "o" },
                      { contact: item?.direction === "i" }
                    )}
                  >
                    <div
                      className={`${
                        item?.message?.type !== "vcard" ? "bubble" : "vcard"
                      } flex relative items-center justify-left max-w-full ${
                        item?.message?.type !== "vcard" && "shadow"
                      } ${
                        item.message?.type === "text" ||
                        item.message?.type === "location"
                          ? "p-4"
                          : ""
                      }`}
                    >
                      {renderMessage(item)}

                      {/* {item.send_status === "sent" && (
                          <div
                            style={{
                              position: "absolute",
                              top: "1px",
                              right: "4px",
                            }}
                          >
                            {((differenceInMinutes(
                              new Date(),
                              new Date(item.createdAt)
                            ) < 55 &&
                              features?.delete) ||
                              features?.reply ||
                              features?.forward) && ( 
                      
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-popover"
                        >
                          {(popupState) => (
                            <>
                              {item.message.type !== "vcard" ? (
                                <button
                                  className="flex flex-row items-center ml-5"
                                  type="button"
                                  color="primary"
                                  {...bindTrigger(popupState)}
                                >
                                //  <Icon>keyboard_arrow_down</Icon>
                                  <KeyboardArrowDownIcon color="disabled" />
                                </button>
                              ) : (
                                ""
                              )}
                              <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <Box px={2} py={1}>
                                  {features?.forward &&
                                    item.message?.type !== "image" && (
                                      <Typography className="py-4">
                                        <button
                                          type="button"
                                          // onClick={() => {
                                          //   setSelectedMessage(item);
                                          //   setOpenEventDialogForwardMessage(
                                          //     true
                                          //   );
                                          // }}
                                        >
                                          Forward
                                        </button>
                                      </Typography>
                                    )}
                                  {/* {features?.reply && (
                                    <Typography className="py-4">
                                      <button
                                        type="button"
                                        id="btn"
                                        //   onClick={() => {
                                        //     setSelectedMessage(item);
                                        //     setShowReplyToBox(true);
                                        //   }}
                                      >
                                        Reply
                                      </button>
                                    </Typography>
                                  )} 
                                  {item.direction === "o" &&
                                  differenceInMinutes(
                                    new Date(),
                                    new Date(item.createdAt)
                                  ) < 55 &&
                                  features?.delete && (
                                  <Typography className="py-4">
                                    <button
                                      type="button"
                                      // onClick={(ev) =>
                                      //   deleteMessage(ev, item)
                                      // }
                                    >
                                      Delete
                                    </button>
                                  </Typography>
                                   )} 
                                </Box>
                              </Popover>
                            </>
                          )}
                        </PopupState>
                      </div>
                      {/* )}
                          </div>
                        )}  */}

                      <Typography
                        className="time absolute text-11 mt-16 mb-24 ltr:right-0  rtl:right-0 -bottom-6 whitespace-nowrap"
                        color="textSecondary"
                      >
                        {item.direction === "o" &&
                          item.sender?.name &&
                          `${item.sender?.name} `}
                        {showDateTime(item.createdAt, true)}
                        {item.direction === "o" &&
                          features.readReceipts &&
                          renderReadIcon(item)}
                      </Typography>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col flex-1 min-h-full">
            <div className="flex flex-col flex-1 items-center justify-center min-h-full">
              <Typography
                className="px-16 pb-24 text-center"
                color="textSecondary"
              >
                No Messages.
              </Typography>
            </div>
          </div>
        )}
      </div>

      <form onSubmit={onMessageSubmit} className="sticky bottom-0 bg-white">
        <div className="relative">
          {emojiPicker && (
            <div
              style={{
                position: "absolute",
                bottom: "2px",
                left: "0px",
                zIndex: "2",
              }}
            >
              <Picker
                autoFocus
                onSelect={addEmoji}
                emojiTooltip
                title="weChat"
                showPreview={false}
                showSkinTones={false}
                onClickOutside={() => setEmojiPicker(false)}
              />
            </div>
          )}
        </div>
        {/* {showReplyToBox ? (
          <TextboxReplyToUi
            message={selectedMessage}
            handleCloseReplyButtonClick={() => setShowReplyToBox(false)}
          />
        ) : null} */}
        {userTyping && (
          <Typography color="error" className="ml-20 mb-4 text-12">
            {userTyping} is typing ...
          </Typography>
        )}
        {showWindowAlert && (
          <Typography color="error" className="p-4 text-4">
            You are only allowed to send template message to this contact.
          </Typography>
        )}
        {/* {showMediaTimeTakingMessage && (
          <Typography color="error" className="ml-20 mb-4 text-12">
            Sending media would take 5-8 seconds. Please wait...
          </Typography>
        )} */}

        {/* ---------------------------------------Chat Message Typing Section------------------------------------ */}

        <div className="flex flex-col rounded-0 w-full border-t-2 px-4">
          {/* *********************************Typing Box [=]... ******************************************* */}
          {selectedContactDetails?.contact_type === "user" &&
          quickReplyData?.length > 0 ? (
            // <div className="flex-1">
            <div className="flex flex-grow flex-shrink-0 mx-16 ltr:mr-48 rtl:ml-48 my-12"></div>
          ) : (
            <>
              {selectedContactDetails &&
                selectedContactDetails?.contact_type === "user" && (
                  <>
                    <TextField
                      autoFocus={false}
                      id="message-input"
                      className="flex-1"
                      style={{ maxHeight: "100px", overflowY: "auto" }}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: "flex flex-grow flex-shrink-0 ltr:mr-48 rtl:ml-48 my-4",
                          input: "",
                        },
                        placeholder: "Type your message",
                      }}
                      InputLabelProps={{
                        shrink: false,
                        className: classes.bootstrapFormLabel,
                      }}
                      onChange={onInputChange}
                      //   onKeyPress={onInputkeyPress}
                      value={messageText}
                      multiline
                      onPaste={handleMediaPaste}
                      disabled={
                        features.noTextWithImage && mediaFile.length !== 0
                      }
                    />
                  </>
                )}
            </>
          )}

          {/* ------------------------Media Section [^]< ---------------------------*/}
          {mediaFile && (
            // <div className="bg-green-500  overflow-hidden ">
            <div className="flex w-full relative overflow-x-scroll">
              {mediaFile.map((eachFile, fileIndex) => (
                <div key={fileIndex} className="block ">
                  {renderMediaFileThumbs(eachFile, fileIndex)}
                </div>
              ))}
            </div>
            // </div>
          )}

          {/* ---------------	---------------chat message actions (+)---------------------------------------------- */}
          <div className="flex items-center justify-between">
            <div>
              {/* {["whatsapp_cloud"].includes(selectedChannelsDetails?.name) && ( */}
              <Tooltip title="Send Template Message">
                <button
                  type="button"
                  onClick={() => {
                    setOpenEmmaTemplateDialog(true);
                  }}
                  className="mr-8"
                >
                  <MessageOutlinedIcon />
                </button>
              </Tooltip>
              {/* )} */}
              {/* {
                <Tooltip title="Quick Reply">
                  <button
                    type="button"
                    className="mx-8"
                    onClick={() => {
                      //   setOpenEventDialogQuickreply(true);
                      //   handleMoreMenuClose();
                    }}
                  >
                    <ReplyAllIcon />
                  </button>
                </Tooltip>
              } */}
              {/* -----------Additional Action Menu--------------- */}
              {features.media && !showMediaLoader && (
                <Tooltip title="Attachments">
                  <button
                    type="button"
                    onClick={() => setShowAttachmentPopup(true)}
                    // className="mx-8"
                  >
                    <AttachFileIcon />
                  </button>
                </Tooltip>
              )}

              {/* -----------------Voice Recorder------------------ */}
              {showMediaLoader && <CircularProgress size={18} />}
              {/* -----------------Emoji Picker ------------------- */}
              <Tooltip title="Emoji">
                <button
                  type="button"
                  onClick={() => setEmojiPicker(!emojiPicker)}
                >
                  <InsertEmoticonIcon className="mx-8" />
                </button>
              </Tooltip>

              {/* ----------------Template Message Button------------- */}
              {/* <Tooltip title="Template Message">
                <button
                  type="button"
                  onClick={() => {
                    setOpenEventDialogQuickreply(true);
                  }}
                  className="mx-8"
                >
                  /
                </button>
              </Tooltip> */}
            </div>

            {/* -----------------------Send Button > -------------------------------- */}
            <IconButton
              disabled={!(chatEnabled && beforeMaxContactsLimit)}
              type="submit"
            >
              <SendIcon color="primary" />
            </IconButton>
          </div>
        </div>
      </form>

      {openEmmaTemplateDialog && (
        <Dialog
          open
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title">
            Send Template
            <CancelIcon
              className="absolute top-1 right-1 mr-8 mt-8"
              onClick={() => setOpenEmmaTemplateDialog(false)}
            >
              close_button
            </CancelIcon>
          </DialogTitle>
          <DialogContent>
            <EmmaTemplatePopup
              selectedContact={selectedContactDetails}
              validateEmmaTemplate={validateEmmaTemplate}
              channelKey={selectedChannelsDetails?.channel_key}
              handleFinalJson={emmaTemplateJson}
              campaignForm={{ integrationID: selectedChannelsDetails[0]?._id }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => setOpenEmmaTemplateDialog(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setValidateEmmaTemplate(!validateEmmaTemplate);
              }}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {showAttachmentPopup && (
        <AddAttachmentPopup
          open={showAttachmentPopup}
          closeDialog={() => {
            setShowAttachmentPopup(false);
          }}
          setMediaFile={setMediaFile}
          mediaFile={mediaFile}
          onlyImage={features.onlyImageAllowed}
          channelName={selectedChannelsDetails?.channel_name}
        />
      )}
    </div>
  );
};

export default Chat;
