import ChatApp from "../app/ChatApp/ChatApp";
import App from "../App";
import Setting from "../app/Setting/Setting";

export const routesConfig = [
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/chat",
    element: <ChatApp />,
  },
  {
    path: "/settings",
    element: <Setting />,
  },
];
