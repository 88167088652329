import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  auth: {
    user: {},
    authToken: "",
  },
  channels: [],
  organisation: {},
  status: "idle",
  channelForm: {
    channel_name: "whatsapp_cloud",
    name: "",
    managerId: "",
    phoneId: "",
    token: "",
  },
  officalTemplates: [],
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    authAction: (state, action) => {
      const { payload } = action;
      if (payload.user && payload.authToken) {
        state.auth = payload;
      } else if (payload.user) {
        state.user = payload.user;
      } else {
        state.authToken = payload.authToken;
      }
    },
    channelsAction: (state, action) => {
      const { payload } = action;
      state.channels = payload.channels;
    },
    organisationAction: (state, action) => {
      const { payload } = action;
      state.organisation = payload.organisation;
    },
    channelFormAction: (state, action) => {
      const {
        payload: { type, data },
      } = action;

      switch (type) {
        case "name":
          state.channelForm.name = data?.name;
          break;
        case "manager_id":
          state.channelForm.managerId = data.managerId;
          break;
        case "phone_id":
          state.channelForm.phoneId = data.phoneId;
          break;
        case "access_token":
          state.channelForm.token = data.token;
          break;
        default:
          break;
      }
    },
    officalTemplatesAction: (state, action) => {
      const { payload } = action;
      state.officalTemplates = payload.templates;
    },
  },
});

export const {
  authAction,
  channelsAction,
  organisationAction,
  officalTemplatesAction,
  channelFormAction,
} = settingSlice.actions;

export const settings = (state) => state.settings;

export default settingSlice.reducer;
