import { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import {
  authAction,
  channelsAction,
  organisationAction,
  settings,
} from "../Setting/settingSlice";
import { chats, contactsAction } from "./chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { leadsAction } from "./chatSlice";
import { getAllChannels, loginUser } from "../services/AuthService";
import jwt_decode from "jwt-decode";
import { createContact, searchContact } from "../services/ChatService";
import Chat from "./Chat";
import { Toolbar, Typography } from "@material-ui/core";
import CircularIndeterminate from "./circular-progress";
import { getFormattedPhone } from "../common";
const ZOHO = window.ZOHO || {};

const ChatApp = () => {
  const settingData = useSelector(settings);
  const chatsData = useSelector(chats);
  const [currentLead, setCurrentLead] = useState({});

  const {
    auth: { authToken },
  } = settingData;

  const { leads, contacts } = chatsData;

  const newContactBody = {
    phone_number: "",
    full_name: "",
    first_name: "",
    last_name: "",
    company: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    remarks: "",
    // "test1-2": "2023-05-07T07:52:10.633Z",
    uid: 0,
    timezone: "Hongkong",
    tags: [],
  };
  const dispatch = useDispatch();

  useEffect(() => {
    async function initialise() {
      let entityIds = [];
      ZOHO.embeddedApp.on("PageLoad", function (data) {
        entityIds = data?.EntityId;
      });

      await ZOHO.embeddedApp.init();
      const org = await ZOHO.CRM.CONFIG.getOrgInfo();
      const currentUser = await ZOHO.CRM.CONFIG.getCurrentUser();
      
      const response = await loginUser({
        userName: currentUser?.users[0].full_name,
        orgId: org?.org[0].id,
      });

      const decodedToken = jwt_decode(response?.token);
      const channelRes = await getAllChannels(response?.token);
      let leadsDataRes = await ZOHO.CRM.API.getRecord({
        Entity: "Leads",
        RecordID: entityIds,
      });

      let leadsData = leadsDataRes?.data;
      setCurrentLead(leadsData[0]);

      let formattedPhone = getFormattedPhone(
        leadsData[0]?.Country,
        leadsData[0]?.Phone
      );

      if (formattedPhone) {
        leadsData = leadsData?.map((eachLead) => {
          return {
            ...eachLead,
            Phone: formattedPhone,
          };
        });
      }

      let contactRes;
      if (leadsData[0]?.Phone) {
        // Search Contact by phone_number
        const params = {
          search: JSON.stringify([
            [
              {
                field_name: "phone_number",
                operator: "exactmatch",
                field_value: `${leadsData[0]?.Phone}`,
              },
            ],
          ]),
          showOnlyContactWithLastMessage: false,
          show_page_count: "no",
        };
        contactRes = await searchContact(params, response?.token);
        if (!contactRes?.data?.length) {
          // If contact found then setContacts
          // else create new contact and set response as contact.
          const currLead = leadsData[0];
          currLead?.Phone &&
            (newContactBody["phone_number"] = `${currLead.Phone}`);
          currLead?.First_Name &&
            (newContactBody["first_name"] = `${currLead.First_Name}`);
          currLead?.Full_Name &&
            (newContactBody["full_name"] = `${currLead.Full_Name}`);
          currLead?.Email && (newContactBody["email"] = `${currLead.Email}`);
          contactRes = await createContact(newContactBody, response?.token);

          contactRes = { ...contactRes, data: [{ ...contactRes?.data }] };
        }
      }

      localStorage.setItem("jwt_token", response?.token);
      dispatch(contactsAction({ contacts: contactRes?.data }));
      dispatch(organisationAction({ organisation: org?.org[0] }));
      dispatch(channelsAction({ channels: channelRes?.data }));
      dispatch(leadsAction({ leads: leadsData }));
      dispatch(authAction({ user: decodedToken, authToken: response?.token }));
    }
    initialise();
  }, []);

  return (
    <div>
      {leads?.length && authToken && contacts?.length > 0 ? (
        <>
          <AppBar className="w-full" elevation={0} position="sticky">
            <Toolbar className="px-4">
              <Typography color="inherit" className="text-8 px-4">
                {currentLead?.Full_Name ||
                  contacts[0]?.full_name ||
                  contacts[0]?.phone_number}
              </Typography>
            </Toolbar>
          </AppBar>
          <Chat className="flex flex-1 relative max-w-full z-10" />
        </>
      ) : (
        <div className="flex flex-1 h-full items-center justify-center m-20">
          <CircularIndeterminate />
        </div>
      )}
    </div>
  );
};

export default ChatApp;
