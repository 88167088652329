import {
  Dialog,
  DialogContent,
  Tab,
  Tabs,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BackupOutlined } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import FileListPopup from "./FileListPopup";
import Compressor from "compressorjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { chatUploadFiles } from "../services/ChatService";
import { settings } from "../Setting/settingSlice";

const compressImageForChannels = ["facebook", "instagram"];

const AddAttachmentPopup = (props) => {
  const {
    closeDialog,
    open,
    mediaFile,
    setMediaFile,
    isSingleFile,
    onlyImage,
    onlyVideo,
    channelName,
  } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [showFileList, setShowFileList] = useState();
  const settingData = useSelector(settings);
  const {
    auth: { authToken },
  } = settingData;

  const [showMediaLoader, setShowMediaLoader] = useState(false);

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  function handleMediaFile(e) {
    setShowMediaLoader(true);
    if (!isSingleFile) {
      if (compressImageForChannels.includes(channelName)) {
        Promise.all(
          [...e.target.files].map(async (file) => {
            let fileToUpload;
            if (file.type.includes("image")) {
              const compressImage = await new Promise((resolve, reject) => {
                const compressor = new Compressor(file, {
                  quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                  maxWidth: 800,
                  convertTypes: [
                    "image/png",
                    "image/webp",
                    "image/jpg",
                    "image/jpeg",
                  ],
                  success: (compressedResult) => {
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server.
                    // compressedResult.lastModifiedDate = new Date();
                    // compressedResult.name = file.name
                    resolve(
                      new File([compressedResult], file.name, {
                        type: file.type,
                      })
                    );
                  },
                });
              });
              fileToUpload = compressImage;
            }
            if (!compressImageForChannels.includes(channelName)) {
              fileToUpload = file;
            }
            return chatUploadFiles(fileToUpload, null, authToken);
          })
        ).then((responses) => {
          setShowMediaLoader(false);
          const uploadedMediaFiles = responses.map(
            (eachResponse) => eachResponse.data
          );
          setMediaFile([...mediaFile, ...uploadedMediaFiles]);
          closeDialog();
        });
      } else {
        Promise.all(
          [...e.target.files].map((file) => {
            return chatUploadFiles(file, null, authToken);
          })
        ).then((responses) => {
          setShowMediaLoader(false);
          const uploadedMediaFiles = responses.map(
            (eachResponse) => eachResponse.data
          );
          setMediaFile([...mediaFile, ...uploadedMediaFiles]);
          closeDialog();
        });
      }
    } else {
      chatUploadFiles(e.target.files[0], null, authToken).then((response) => {
        const uploadedMediaFiles = response.data;
        setMediaFile(uploadedMediaFiles);
        setShowMediaLoader(false);
        closeDialog();
      });
    }
  }

  let mediaType = "";
  if (onlyImage) {
    mediaType = "image/*";
  } else if (onlyVideo) {
    mediaType = "video/*";
  } else {
    mediaType =
      "audio/*,.heic, .hevc, .heif, .pdf,.doc, .docx, application/msword, application/pdf, image/*, video/*";
  }

  return (
    <div className="absolute rounded-3xl">
      <Dialog
        className="rounded-3xl"
        onClose={closeDialog}
        fullWidth
        open={open}
      >
        <div>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            scrollButtons="off"
            className="w-full"
          >
            <Tab fullWidth label="Upload" />
            <Tab
              fullWidth
              // className={`${
              //   checkPermissions("manageAttachment") ? "" : " hidden"
              // }`}
              label="File Manager"
            />
          </Tabs>
          <CancelIcon
            className="absolute top-0 right-0 mr-8 mt-2 "
            onClick={closeDialog}
          >
            close_button
          </CancelIcon>
        </div>
        <DialogContent className="p-0">
          {selectedTab === 0 && (
            <div className="flex flex-col items-center my-14">
              <label
                htmlFor="upload-photo"
                // style={{ width: 'fitContent' }}
                className="flex flex-col items-center p-0 my-4"
              >
                <input
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  accept={mediaType}
                  className="hidden"
                  multiple
                  onChange={(e) => handleMediaFile(e)}
                />

                <Button
                  disabled={showMediaLoader}
                  variant="outlined"
                  component="span"
                  color="primary"
                  style={{ borderRadius: "100% !important" }}
                >
                  {showMediaLoader ? (
                    <CircularProgress className="mr-10" size={18} />
                  ) : (
                    <BackupOutlined className="mr-10" />
                  )}
                  {showMediaLoader ? "Uploading..." : "Upload File"}
                </Button>
              </label>
            </div>
          )}
          {selectedTab === 1 && (
            <FileListPopup
              open={showFileList}
              closeDialog={closeDialog}
              setMediaFile={setMediaFile}
              isSingleFile={isSingleFile}
              onlyImage={onlyImage}
              onlyVideo={onlyVideo}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddAttachmentPopup;
