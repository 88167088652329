import axios from "axios";
import { toast } from "react-toastify";

export const searchContact = (params, authToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        url: `${process.env.REACT_APP_BASE_API_URL}/api/contacts/page/1`,
        method: "GET",
        params: params,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios(config);
      if (response?.data.success) {
        return resolve(response?.data);
      }
    } catch (error) {
      console.log("Error in getAllChannels :", error);
      toast.error(error.message, {
        theme: "colored",
      });
      return reject();
    }
  });
};

export const createContact = (data, authToken) => {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        url: `${process.env.REACT_APP_BASE_API_URL}/api/contacts`,
        method: "POST",
        data: data,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios(config);
      if (response?.data.success) {
        return resolve(response?.data);
      }
    } catch (error) {
      console.log("Error in getAllChannels :", error);
      toast.error(error.message, {
        theme: "colored",
      });
      return reject();
    }
  });
};

export const chatUploadFiles = (files, endpoint, authToken) => {
  endpoint = endpoint || "upload-file";
  const chatFileUpload = new FormData();
  chatFileUpload.append(`common_file`, files);
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}/api/common/${endpoint}`,
        chatFileUpload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          theme: "colored",
        });
        reject(error);
      });
  });
};

export const getFiles = (authToken) => {
  return new Promise((resolve, reject) => {
    const config = {
      url: `${process.env.REACT_APP_BASE_API_URL}/api/attachments`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    
    axios(config)
      .then((response) => {
        if (response.data.success) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          theme: "colored",
        });
        reject(error);
      });
  });
};

export const getChatMessages = (pageNum, authToken, Allparams) => {
  return new Promise((resolve, reject) => {
    const config = {
      url: `${process.env.REACT_APP_BASE_API_URL}/api/messages/page/${pageNum}`,
      method: "GET",
      params: Allparams,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
