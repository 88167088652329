import Button from "@material-ui/core/Button";
import { useSelector } from "react-redux";
import { settings } from "./settingSlice";

const Channels = () => {
  const settingData = useSelector(settings);
  const { channels } = settingData;

  const manageTemplateLink = `https://business.facebook.com/wa/manage/message-templates/?business_id=${channels[0]?.whatsapp_business_account?.business_manager_id}&waba_id=${channels[0]?.whatsapp_business_account?.whatsapp_business_account_id}`;

  return (
    <div className="flex justify-between">
      <h2>You are connected to {channels[0]?.title}</h2>
      <Button variant="outlined">
        <a
          href={manageTemplateLink}
          className="no-underline"
          target="_blank"
          rel="noreferrer"
        >
          Manage Templates
        </a>
      </Button>
    </div>
  );
};

export default Channels;
