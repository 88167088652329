import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import { DescriptionOutlined } from "@material-ui/icons";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  templateCard: {
    // backgroundColor: 'transparent',
    boxShadow: "0 0 0.25rem rgba(0, 0, 0, 0.3)",
    borderRadius: "10px",
    maxWidth: "400px",
    minWidth: "100px",
  },
}));

export default function TemplateMessage(props) {
  const classes = useStyles();
  const { template, whatsappTemplateArr } = props;
  //   const whatsappTemplateArr = {
  //     data: [
  //       {
  //         name: "hello_world",
  //         previous_category: "ACCOUNT_UPDATE",
  //         components: [
  //           {
  //             type: "HEADER",
  //             format: "TEXT",
  //             text: "Hello World",
  //           },
  //           {
  //             type: "BODY",
  //             text: "Welcome and congratulations!! This message demonstrates your ability to send a WhatsApp message notification from the Cloud API, hosted by Meta. Thank you for taking the time to test with us.",
  //           },
  //           {
  //             type: "FOOTER",
  //             text: "WhatsApp Business Platform sample message",
  //           },
  //         ],
  //         language: "en_US",
  //         status: "APPROVED",
  //         category: "MARKETING",
  //         id: "1005400347486126",
  //         _id: "63d36f6f6d7147042dae0bda",
  //       },
  //     ],
  //   };
  const getCardTemplate = (headerObj, bodyObj, footerObj, buttonObj) => {
    return (
      <Card className={classes.templateCard}>
        {headerObj.type &&
          headerObj.type !== "text" &&
          headerObj.type !== "document" &&
          headerObj.data && (
            <CardMedia
              component={headerObj.type === "image" ? "img" : "video"}
              height="80"
              image={headerObj.data}
              alt="image"
            />
          )}
        <CardContent>
          {headerObj.type === "document" && headerObj.data && (
            <div>
              <DescriptionOutlined
                style={{ width: "40px", height: "40px", margin: "5px" }}
              />
              <Typography variant="body2" component="div">
                {headerObj.fileName}
              </Typography>
              <a href={headerObj.data}>
                <GetAppRoundedIcon />
              </a>
            </div>
          )}
          {headerObj.type === "text" && (
            <Typography className="mb-4" variant="subtitle1" component="div">
              {headerObj.data}
            </Typography>
          )}
          {bodyObj?.data && (
            <Typography gutterBottom variant="body1" component="div">
              {bodyObj?.data}
            </Typography>
          )}
          {footerObj?.data && (
            <Typography variant="caption">{footerObj?.data}</Typography>
          )}
        </CardContent>
        {buttonObj.length !== 0 && (
          <CardActions>
            {buttonObj.map((buttonVal, i) => {
              return (
                <>
                  {buttonVal.type === "URL" ? (
                    <Button
                      variant="contained"
                      key={i}
                      size="small"
                      href={buttonVal.url}
                      target="_blank"
                    >
                      {buttonVal.text}
                      <span className="material-icons mr-10">open_in_new</span>
                    </Button>
                  ) : (
                    <Button variant="contained" key={i} size="small">
                      {buttonVal.text}
                    </Button>
                  )}
                </>
              );
            })}
          </CardActions>
        )}
      </Card>
    );
  };

  const renderUi = () => {
    let templateFound = false;
    const headerObj = {};
    const bodyObj = {};
    const footerObj = {};
    let buttonObj = [];
    whatsappTemplateArr &&
      whatsappTemplateArr?.data?.forEach((templateJson) => {
        if (
          template.name === templateJson.name &&
          template.language.code === templateJson.language
        ) {
          templateFound = true;
          templateJson.components.forEach((eachComponent) => {
            switch (eachComponent.type) {
              case "HEADER": {
                if (eachComponent.format === "TEXT") {
                  headerObj.type = "text";
                  headerObj.data = eachComponent.text;
                }

                const headerData = template.components?.filter(
                  (componentData) => componentData.type === "header"
                );
                if (headerData && headerData.length) {
                  headerData[0]?.parameters?.forEach((parameterData, index) => {
                    switch (parameterData.type) {
                      case "image": {
                        headerObj.type = parameterData.type;
                        headerObj.data = parameterData.image.link;
                        headerObj.fileName = parameterData.image.filename;
                        break;
                      }
                      case "video": {
                        headerObj.type = parameterData.type;
                        headerObj.data = parameterData.video.link;
                        headerObj.fileName = parameterData.video.filename;
                        break;
                      }
                      case "document": {
                        headerObj.type = parameterData.type;
                        headerObj.data = parameterData?.document?.link;
                        headerObj.fileName = parameterData?.document?.filename;
                        break;
                      }
                      case "text": {
                        headerObj.data = headerObj.data.replace(
                          `{{${index + 1}}}`,
                          parameterData.text
                        );
                        break;
                      }
                      default:
                        break;
                    }
                  });
                }
                break;
              }
              case "BODY": {
                bodyObj.data = eachComponent.text;
                const bodyData = template.components?.filter(
                  (componentData) => componentData.type === "body"
                );
                if (bodyData && bodyData.length) {
                  bodyData[0]?.parameters?.forEach((parameterData, index) => {
                    bodyObj.data = bodyObj.data.replace(
                      `{{${index + 1}}}`,
                      parameterData.text
                    );
                  });
                }
                break;
              }
              case "FOOTER": {
                footerObj.data = eachComponent.text;
                break;
              }
              case "BUTTONS": {
                const dummyButtonArr = JSON.parse(
                  JSON.stringify(eachComponent.buttons)
                );
                dummyButtonArr.forEach((buttonData) => {
                  if (buttonData.type === "URL") {
                    const buttonUrlData = template.components?.filter(
                      (componentData) =>
                        componentData.type === "button" &&
                        componentData.sub_type === "url"
                    );
                    if (buttonUrlData && buttonUrlData.length) {
                      buttonUrlData[0]?.parameters?.forEach(
                        (parameterData, index) => {
                          buttonData.url = buttonData.url.replace(
                            `{{${index + 1}}}`,
                            parameterData.payload
                          );
                        }
                      );
                    }
                  }
                });
                buttonObj = dummyButtonArr;
                break;
              }
              default:
                break;
            }
          });
        }
      });
    if (templateFound) {
      return getCardTemplate(headerObj, bodyObj, footerObj, buttonObj);
    }
    if (!templateFound && template && template.name) {
      return <Typography>Template Message - {template.name}</Typography>;
    }
    return "";
  };

  return (
    <>
      {/* <div style={{ color: 'primary' }} > */}
      {/* <Typography>Template Message - {template.name}</Typography> */}
      {renderUi()}
      {/* </div> */}
    </>
  );
}
