import React from 'react';
import linkifyHtml from 'linkify-html';

const MessageMention = ({ message, participants, direction, media }) => {
	let msg = `${message}`;
	let modifiedMsg;
	let options;
	if (!media) {
		options =
			direction === 'i'
				? {
						attributes: {
							style: `color: blue; text-decoration: underline; word-break: break-all;border:0px;background-color:transparent`
						},
						target: '_blank'
				  }
				: {
						attributes: {
							style: `color: white; text-decoration: underline; word-break: break-all;border:0px;background-color:transparent`
						},
						target: '_blank'
				  };
	} else {
		options = {
			attributes: {
				style: `color: blue; text-decoration: underline; word-break: break-all;border:0px;background-color:transparent`
			},
			target: '_blank'
		};
	}

	if (direction === 'i') {
		participants?.map(({ name, phone }) => {
			const number = `@${phone}`;
			const n = `<Tooltip title="${phone}" placement="top"><button>@${name}</button></Tooltip>`;
			if (msg.includes(phone)) {
				const regex = new RegExp(number, 'g');
				modifiedMsg = msg.replace(regex, n);
				msg = modifiedMsg;
			}
			modifiedMsg = msg;
			return msg;
		});
	}

	if (direction === 'o') {
		participants?.map(({ name, phone }) => {
			// m = `${message}`;
			const number = `@${phone}`;
			const FullName = `@${name}`;
			const n = `<Tooltip title="${phone}" placement="top"><button>@${name}</button></Tooltip>`;

			if (msg.includes(name)) {
				const regex = new RegExp(FullName, 'g');
				modifiedMsg = msg.replace(regex, n);
				msg = modifiedMsg;
			}
			if (msg.includes(phone)) {
				const regex = new RegExp(number, 'g');
				modifiedMsg = msg.replace(regex, n);
				msg = modifiedMsg;
			}
			modifiedMsg = msg;
			return msg;
		});
	}

	let messageRender = msg;
	try {
		messageRender = linkifyHtml(`${msg}`, options);
	} catch (error) {
		// Nothing to handle
	}

	return <span className="break-all" dangerouslySetInnerHTML={{ __html: messageRender }} />;
};

export default MessageMention;
